import { v4 as uuidv4 } from 'uuid'

import { DEFAULT_ERROR_MESSAGE_NAME, ERROR_MESSAGE } from 'shared/constants'
import { getLocale } from 'utils/locale'
import Bugsnag from 'utils/helpers/bugsnagHelper'

import { getController } from './getFetchController'

export const defaultRequestInit = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
}

export const NO_CONTENT_STATUS_CODE = 204

const { APP_INTERNAL_API } = process.env

export function getAPIBaseURI(): string {
  const protocol = process.env.API_PROTOCOL || 'https'
  const apiHost = process.env.API_HOST

  return typeof window === 'undefined' && APP_INTERNAL_API
    ? `http://${APP_INTERNAL_API}`
    : `${protocol}://${apiHost}`
}

export const defaultBaseURI = getAPIBaseURI()

const UNKNOWN_ERROR_KEYWORDS = [
  'ECONNRESET',
  'EAI_AGAIN',
  'ETIMEDOUT',
  'ENOTFOUND',
]

interface ErrorFromServer {
  error: {
    type: string
    code: string
    name?: string
    error_message: string
    arguments?: {
      merchant_support_email?: string
    }
  }
}

export type HttpError = {
  name: string
  errorName: string
  statusCode: number
  message: string
  code: string
  arguments?: {
    merchant_support_email?: string
  }
}

export interface ResponseError {
  ok?: boolean
  statusCode?: number
  error?: HttpError
}

export type ResponseData<T> = T & ResponseError

const MAX_RETRY_COUNT = 2

export class Api {
  private baseURI: string

  private requestInit: RequestInit

  public controller: AbortController | null

  static UNKNOWN_ERROR_COUNT_MEMORY = {}

  constructor({
    init = defaultRequestInit,
    authToken,
    baseURI = defaultBaseURI,
    headers = {},
  }: {
    init?: RequestInit
    authToken?: string
    baseURI?: string
    headers?: HeadersInit
  } = {}) {
    const controller = (process as any).browser ? getController() : null

    this.controller = controller

    this.requestInit = {
      ...init,
      headers: Object.assign(
        {},
        defaultRequestInit.headers,
        init?.headers,
        authToken
          ? {
              Authorization: `Bearer ${encodeURI(authToken)}`,
            }
          : {},
        headers
      ),
      signal: this.controller?.signal,
    }
    this.baseURI = baseURI
  }

  static fetch<T>(
    api: RequestInfo,
    data: RequestInit = defaultRequestInit,
    needExtra = false
  ): Promise<ResponseData<T>> {
    data.headers = {
      ...data.headers,
      'Accept-Language': getLocale(),
      'X-Pay-Trace-Id': uuidv4(),
    }

    return fetch(api, data)
      .then(response => {
        if (response.status === NO_CONTENT_STATUS_CODE) {
          return {
            ok: true,
            statusCode: response.status,
          }
        }
        if (response.ok && response.body) {
          const dataJson = response.json()

          if (needExtra) {
            return {
              data: dataJson,
              headers: response.headers,
            }
          }

          return dataJson
        }
        throw response
      })
      .catch(async error => {
        let unknownError

        if (error instanceof Error) {
          Bugsnag.notify(
            JSON.stringify({ error, from: 'fetch error catch 1', api, data })
          )
          const unknownErrors = UNKNOWN_ERROR_KEYWORDS.filter(
            keyword => ~error.message.indexOf(keyword)
          )

          unknownError = unknownErrors?.[0]

          if (
            unknownError &&
            Api.UNKNOWN_ERROR_COUNT_MEMORY[unknownError] &&
            Api.UNKNOWN_ERROR_COUNT_MEMORY[unknownError] < MAX_RETRY_COUNT
          ) {
            Api.UNKNOWN_ERROR_COUNT_MEMORY[unknownError] =
              Api.UNKNOWN_ERROR_COUNT_MEMORY
                ? Api.UNKNOWN_ERROR_COUNT_MEMORY[unknownError] + 1
                : 0

            return await Api.fetch(api, data)
          }

          return {
            error: {
              code: 'network_error',
              errorMessage: 'Network Error',
            },
          }
        }

        if (unknownError) {
          Api.UNKNOWN_ERROR_COUNT_MEMORY[unknownError] = 0
        }

        return error
          .json()
          .then((responseJson: ErrorFromServer) => {
            const translationText = ERROR_MESSAGE.find(
              item => item.code === responseJson.error.code
            )

            Bugsnag.notify(
              JSON.stringify({
                error: responseJson,
                from: 'fetch error catch 2',
                api,
                data,
              })
            )

            return {
              error: {
                // Combine the error object from BE { code, error_message, type, param, arguments }
                // and the old one(Http Error) { name, errorName, statusCode, message }
                // Using them all is just in case something is missed.
                // Please refactor this if possible in the future.
                ...responseJson.error,
                name: responseJson.error.code || '',
                code: responseJson.error.code || '',
                error_message: responseJson.error.error_message || null,
                errorName: translationText
                  ? translationText.errorName
                  : DEFAULT_ERROR_MESSAGE_NAME,
                statusCode: error.status,
                message: error.statusText || '',
                arguments: responseJson.error?.arguments || null,
              },
            }
          })

          .catch(err => {
            Bugsnag.notify(
              JSON.stringify({
                error: err,
                from: 'fetch error catch 3',
                api,
                data,
              })
            )

            return {
              error: {
                name: 'invalid request',
                errorName: DEFAULT_ERROR_MESSAGE_NAME,
                statusCode: 500,
                message: 'please check you request',
              },
            }
          })
      })
  }

  get = <T = any>(
    api: RequestInfo,
    data: any = {},
    newBaseURI?: string | null
  ): Promise<ResponseData<T>> => {
    const baseURI = newBaseURI !== null ? newBaseURI || this.baseURI : ''
    const url = new URL(baseURI + api)

    if (Object.keys(data).length > 0) {
      url.search = new URLSearchParams(data).toString()
    }

    return Api.fetch<T>(url.toString(), this.requestInit)
  }

  getExtra = <T = any>(
    api: RequestInfo,
    data: any = {},
    newBaseURI?: string | null
  ): {
    data: Promise<ResponseData<T>>
    headers: Headers
    error?: HttpError
  } => {
    const baseURI = newBaseURI !== null ? newBaseURI || this.baseURI : ''
    const url = new URL(baseURI + api)

    if (Object.keys(data).length > 0) {
      url.search = new URLSearchParams(data).toString()
    }

    return Api.fetch<T>(url.toString(), this.requestInit, true) as any
  }

  post = <T = any>(
    api: RequestInfo,
    data?: any,
    newBaseURI?: string | null
  ): Promise<ResponseData<T>> => {
    const baseURI = newBaseURI !== null ? newBaseURI || this.baseURI : ''
    const url = baseURI + api
    const requestData = Object.assign({}, this.requestInit, {
      method: 'POST',
    })

    if (data) {
      requestData['body'] = JSON.stringify(data)
    }

    return Api.fetch<T>(url, requestData)
  }

  put = <T = any>(
    api: RequestInfo,
    data?: any,
    newBaseURI?: string | null
  ): Promise<ResponseData<T>> => {
    const baseURI = newBaseURI !== null ? newBaseURI || this.baseURI : ''
    const url = baseURI + api
    const requestData = Object.assign({}, this.requestInit, {
      method: 'PUT',
    })

    if (data) {
      requestData['body'] = JSON.stringify(data)
    }

    return Api.fetch<T>(url, requestData)
  }

  delete = <T = any>(
    api: RequestInfo,
    data?: any,
    newBaseURI?: string | null
  ): Promise<ResponseData<T>> => {
    const baseURI = newBaseURI !== null ? newBaseURI || this.baseURI : ''
    const url = baseURI + api
    const requestData = Object.assign({}, this.requestInit, {
      method: 'DELETE',
    })

    if (data) {
      requestData['body'] = JSON.stringify(data)
    }

    return Api.fetch<T>(url, requestData)
  }
}

export default Api
