import { CRO_CURRENCIES } from 'constants/utils'
import { Token } from 'contract/web3'
import { getTransferCoinMap, isCronosCro, isNonCronosCro } from 'contract/utils'

export const isCroNative = (currency: string) =>
  currency === CRO_CURRENCIES.CRO_NATIVE

export const isCroCronos = (currency: string) =>
  currency === CRO_CURRENCIES.CRO_CRONOS

export const isCroEthereum = (currency: string) =>
  currency === CRO_CURRENCIES.ERC20

export const isCro = (currency: string) =>
  isCroNative(currency) || isCroCronos(currency) || isCroEthereum(currency)

export const isCroToken = (token: Token): boolean => {
  return isCronosCro(token) || isNonCronosCro(token)
}

export const isTransferCoin = (symbol: string, chainId): boolean => {
  return Boolean(getTransferCoinMap(chainId)[symbol])
}
