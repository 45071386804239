import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import TagManager from 'react-gtm-module'

import { getBooleanFromStr } from 'utils/helpers'

const EventKey = 'event'

const Pages = {
  set_wallet: 'Set Wallet',
  set_email: 'Set Email',
  checkout: 'Checkout',
  invoice: 'Invoice',
  subscription: 'Subscription',
}

const Steps = {
  [Pages.set_wallet]: 'Step 1',
  [Pages.set_email]: 'Step 2',
  [Pages.checkout]: 'Step 3',
  [Pages.invoice]: 'Step 3',
  [Pages.subscription]: 'Step 3',
}

const PaymentMethods = {
  CDC: 'CDC',
  MetaMask: 'MetaMask',
  WalletConnect: 'WalletConnect',
  OtherWallets: 'Other Wallets',
}

const getPaymentMethods = ({
  offchain,
  isMetaMask,
  isWalletConnect,
  isOtherWalletForOnchain,
}: {
  [key: string]: boolean
}) => {
  if (offchain) {
    return PaymentMethods.CDC
  }
  if (isMetaMask) return PaymentMethods.MetaMask
  if (isWalletConnect) return PaymentMethods.WalletConnect
  if (isOtherWalletForOnchain) return PaymentMethods.OtherWallets
}

const getEventName = ({
  from,
  isCheckout,
  isMetaMask,
  isOtherWalletForOnchain,
  isWalletConnect,
  offchain,
  pathname,
}) => {
  const curPage = Pages[pathname.replace('/', '')]
  const curStep = Steps[curPage]
  const curPayment = getPaymentMethods({
    offchain: getBooleanFromStr(offchain),
    isMetaMask: getBooleanFromStr(isMetaMask),
    isWalletConnect: getBooleanFromStr(isWalletConnect),
    isOtherWalletForOnchain: getBooleanFromStr(isOtherWalletForOnchain),
  })

  // Land to Checkout Step 3 Directly
  if (isCheckout && curPage === Pages.checkout && !from) {
    return [`Direct Checkout - ${curPayment}`, !!curPayment]
  }

  // the following is Normal Checkout Flow
  if (curStep === Steps[Pages.set_wallet])
    return [`${curStep} ${curPage}`, !!(curStep && curPage)]

  return [
    `${curStep} ${curPage} - ${curPayment}`,
    !!(curStep && curPage && curPayment),
  ]
}

export function useGTM() {
  const router = useRouter()
  const { query, pathname, locale } = router
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const {
      from,
      isCheckout,
      isMetaMask,
      isMobile,
      isOtherWalletForOnchain,
      isWalletConnect,
      offchain,
    } = query

    const [eventName, isTracking] = getEventName({
      from,
      isCheckout,
      isMetaMask,
      isOtherWalletForOnchain,
      isWalletConnect,
      offchain,
      pathname,
    })

    if (!isTracking) return

    const tagManagerArgs = {
      dataLayer: {
        [EventKey]: eventName,
        isMobile: !!isMobile,
        locale,
      },
      // dataLayerName: 'PageDataLayer',
    }

    TagManager.dataLayer(tagManagerArgs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, locale])

  // Initiate Google Tag Manager
  useEffect(() => {
    // react-gtm-module expects to be called only on the client side, so useEffect ensures it is not called during SSR
    if (
      !initialized &&
      !pathname.includes('/button') &&
      !pathname.includes('/banner')
    ) {
      TagManager.initialize({ gtmId: 'GTM-WG9FXPH' })
      setInitialized(true)
    }
  }, [initialized, pathname])
}

export const trackingEventName = (eventName: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      [EventKey]: eventName,
    },
  })
}
