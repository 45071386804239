export * from '../ENV'

export enum ENV {
  DEV = 'development',
  TESTING = 'testing',
  TEST = 'test',
  PRODUCTION = 'production',
  STAGING = 'staging',
}

export const SDK_PATHS = {
  ROOT: '/sdk',
  LIBRARY: '/sdk/lib',
  BUTTON: '/button',
  CHECKOUT: '/checkout',
  DOWNLOAD: '/download',
  QRCODE: '/images/*',
  INVOICE: '/invoice',
  ONCHAIN_OUTBOUND: '/refund_outbound',
  SUBSCRIPTION: '/subscription',
  BANNER: '/banner',
}

export enum SDK_QUERY_KEYS {
  PUBLISHABLE_KEY = 'publishable-key',
  STAGING = 'staging',
  DEBUG = 'debug',
}

export const API_PATHS = {
  CREATE_PAYMENT: '/api/payments',
  CREATE_SUBSCRIPTION: '/api/subscriptions',
  SHOW_PAYMENT: '/api/payments/:id',
  CURRENT_MERCHANT: '/api/current_merchant?publishableKey=:key',
  SHOW_SUBSCRIPTION: '/api/subscriptions/:id',
  CANCEL_PAYMENT: '/api/payments/:id/cancel',
  REFRESH_PAYMENT: '/api/payments/:id/refresh',
  REFRESH_SUBSCRIPTION: '/api/subscriptions/:id/refresh',
  SAVE_EMAIL: '/api/customers',
  CUSTOMER_PROVIDE_INFO: '/api/payments/:id/customer_provided_info',
  ENABLE_ON_CHAIN_PAYMENT: '/api/payments/:id/enable_onchain',
  COMMIT_ONCHAIN_REFUND_ADDRESS: '/api/conduct_address',
  CHECK_ONCHAIN_OUTBOUND_STATUS: '/api/onchain_outbound',
  SYSTEM_MAINTENANCE_TIME: '/sys/maintenance',
  WITHDRAW_CURRENCIES: '/api/withdraw_currencies',
  FUNDING_RESOURCES: '/api/funding_sources',
  GET_CASHBACK_RATE: '/api/cashback_rate',
  TRACKING: '/tracking/events',
  CHAINS: '/api/currency_configs/chains',
  BASE_TOKENS: '/api/currency_configs/base_tokens',
  BASE_TOKENS_BY_NETWORK: '/api/currency_configs/base_tokens_by_network',
  FIATS: '/api/currency_configs/fiats',
  CURRENCY_CONFIGS: '/api/currency_configs/index',
  SAVE_CARD_INFO: '/api/customer_cards',
  SAVE_BILLING_ADDRESS: '/api/customer_cards/:id',
  CREATE_PURCHASE: '/api/crypto_purchases',
  PURCHASE_PAY: '/api/crypto_purchases/:id/pay',
  GET_PURCHASE: '/api/crypto_purchases/:id',
  REFRESH_PURCHASE: '/api/crypto_purchases/:id/refresh',
  GET_PURCHASE_PUBLIC: '/api/crypto_purchases/:id/detail_info',
  SEND_OTP: '/api/crypto_purchases/send_otp',
  VALIDATE_OTP: '/api/crypto_purchases/validate_otp',
  TRANSACTION_LIMIT: '/api/crypto_purchases/:id/transaction_limit',
  UPDATE_PURCHASE_CUSTOMER: '/api/crypto_purchases/update_customer',
  GET_ASSETS: '/api/crypto_purchases/assets',
  DELETE_CARD: '/api/customer_cards/:id',
  GET_TRANSACTION_LIMIT_CONFIG:
    '/api/crypto_purchase_configs/transaction_limit',
  SAVE_SESSION_ID: '/api/crypto_purchases/:id/update_session_id',
  RETRY: '/api/crypto_purchases/:id/retry',
  UPDATE_NOTIFICATION_SETTING:
    '/api/customer_accounts/:id/notification_setting',
  CREATE_WORKD_FLOW_RUN: '/api/onfidos/workflow_run',
  CHECK_ONFIDO_RESULT: '/api/onfidos/check',
  GET_TOKEN_PRICE: '/api/crypto_purchases/:currency/rate',
  GET_COUNTRIES: '/api/support_countries',
  GET_APPLE_PAY_SESSION: '/api/crypto_purchases/apple_pay_session',
  REPORT_LOCATION: '/api/pay/v2/payment/location',
}

export const WEBSOCKET_API_PATHS = {
  PAYMENT: '/pusher/connection',
}

export enum HTTP_HEADERS {
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization',
  X_FRAME_OPTIONS = 'X-Frame-Options',
  TRACE_ID = 'X-Pay-Trace-Id',
}

export enum HTTP_CONTENT_TYPE {
  TEXT = 'text/plain',
  HTML = 'text/html',
  JAVASCRIPT = 'application/javascript',
}

export enum HTTP_STATUS_CODE {
  SUCCESS = 200,
  CLIENT_ERROR = 400,
  SERVER_ERROR = 500,
}

export const QUERY_BOOL = {
  TRUE: 'true',
  FALSE: 'false',
}

export const CUSTOMER_PROVIDED_EMAIL_CHECKOUT_KEY =
  'customer_provided_email_checkout'
export const CUSTOMER_PROVIDED_EMAIL_INVOICE_KEY =
  'customer_provided_email_invoice'

export const DEFAULT_ERROR_MESSAGE_NAME = 'statusTip'

export const ERROR_MESSAGE = [
  {
    code: 'cannot_change_email_after_paid',
    errorName: 'cannotChangeEmail',
  },
  {
    code: 'daily_amount_limit',
    errorName: 'dailyAmountLimit',
  },
  {
    code: 'invalid_currency',
    errorName: 'invalidCurrency',
  },
  {
    code: 'api_key_invalid',
    errorName: 'apiKeyInvalid',
  },
  {
    code: 'annual_amount_limit',
    errorName: 'annualAmountLimit',
  },
  {
    code: 'invalid_amount',
    errorName: 'notProvidedAmount',
  },
  {
    code: 'invalid_expire_time',
    errorName: 'invalidExpireTime',
  },
]
