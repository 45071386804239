import { useMemo } from 'react'
import { keyInterface, ConfigInterface } from 'swr'

import Api from 'utils/api/fetchApi'

import { useFetch } from './useFetch'
import { usePublishableKey } from './usePublishableKey'

export interface QueryConfig {
  withToken?: boolean
  baseURI?: string
  token?: string
  headers?: HeadersInit
}
export function useQuery<Data>(
  key: keyInterface,
  config?: ConfigInterface & QueryConfig
) {
  const { withToken = true, baseURI, token, headers, ...options } = config || {}
  const publishableKey = usePublishableKey()
  const fetcher = useMemo(
    () =>
      new Api({
        authToken: withToken ? token || publishableKey : undefined,
        baseURI,
        headers,
      }),
    [baseURI, publishableKey, token, withToken, headers]
  )
  const result = useFetch<Data>(key, fetcher.get, options)

  return { ...result, controller: fetcher.controller }
}
