import { API_PATHS, WEBSOCKET_API_PATHS } from 'shared/constants'

export function getApiProtocol() {
  return process.env.API_PROTOCOL || 'http'
}

export function getWsPaymentUrl(): string {
  // always `Https`
  return `https://${process.env.WEBSOCKET_API_HOST}${WEBSOCKET_API_PATHS.PAYMENT}`
}

export function getPaymentApi(id: string) {
  return API_PATHS.SHOW_PAYMENT.replace(/:id/, id)
}

export function getSubscriptionApi(id: string) {
  return API_PATHS.SHOW_SUBSCRIPTION.replace(/:id/, id)
}

export function getRefreshPaymentApi(id: string) {
  return API_PATHS.REFRESH_PAYMENT.replace(/:id/, id)
}

export function getProvidedInfoApi(id: string) {
  return API_PATHS.CUSTOMER_PROVIDE_INFO.replace(/:id/, id)
}

export function getEnableOnchainPaymentApi(id: string) {
  return API_PATHS.ENABLE_ON_CHAIN_PAYMENT.replace(/:id/, id)
}

export function getSaveCustomerEmailApi() {
  return API_PATHS.SAVE_EMAIL
}

export function getCheckOnchainOutboundStatusApi(token: string) {
  return `${API_PATHS.CHECK_ONCHAIN_OUTBOUND_STATUS}?token=${token}`
}

export function getCommitOnchainRefundAddressApi(): string {
  return API_PATHS.COMMIT_ONCHAIN_REFUND_ADDRESS
}

export function getSystemMaintenanceApi(): string {
  return API_PATHS.SYSTEM_MAINTENANCE_TIME
}

export function getWithdrawCurrenciesApi(token: string) {
  return `${API_PATHS.WITHDRAW_CURRENCIES}?token=${token}`
}

export function getFundingResourcesApi() {
  return API_PATHS.FUNDING_RESOURCES
}

export function getCurrencyConfigs(): string {
  return API_PATHS.CURRENCY_CONFIGS
}

export function saveCardInfoApi(): string {
  return API_PATHS.SAVE_CARD_INFO
}

export function saveBillingAddressApi(id: string): string {
  return API_PATHS.SAVE_BILLING_ADDRESS.replace(':id', id)
}

export function createPurchaseApi(): string {
  return API_PATHS.CREATE_PURCHASE
}

export function purchasePayApi(id: string): string {
  return API_PATHS.PURCHASE_PAY.replace(':id', id)
}
export function getPurchaseApi(id: string): string {
  return API_PATHS.GET_PURCHASE.replace(':id', id)
}

export function refreshPurchaseApi(id: string): string {
  return API_PATHS.REFRESH_PURCHASE.replace(':id', id)
}

export function getPurchaseDataPublicApi(id: string): string {
  return API_PATHS.GET_PURCHASE_PUBLIC.replace(':id', id)
}

export function getCurrentMerchantApi(key: string): string {
  return API_PATHS.CURRENT_MERCHANT.replace(':key', key)
}

export function sendOtpApi(): string {
  return API_PATHS.SEND_OTP
}

export function validateOtpApi(): string {
  return API_PATHS.VALIDATE_OTP
}

export function transactionLimitApi(id: string): string {
  return API_PATHS.TRANSACTION_LIMIT.replace(':id', id)
}

export function updatePurchaseCustomerApi(): string {
  return API_PATHS.UPDATE_PURCHASE_CUSTOMER
}

export function getAssetsApi(): string {
  return API_PATHS.GET_ASSETS
}

export function deleteCardApi(id: string): string {
  return API_PATHS.DELETE_CARD.replace(':id', id)
}

export function transactionLimitConfigApi(): string {
  return API_PATHS.GET_TRANSACTION_LIMIT_CONFIG
}

export function saveSessionIdApi(id: string): string {
  return API_PATHS.SAVE_SESSION_ID.replace(':id', id)
}

export function retryApi(id: string): string {
  return API_PATHS.RETRY.replace(':id', id)
}

export function updatePromotionalEmailNotificationSettingApi(
  id: string
): string {
  return API_PATHS.UPDATE_NOTIFICATION_SETTING.replace(':id', id)
}

export function createWorkFlowRunApi(): string {
  return API_PATHS.CREATE_WORKD_FLOW_RUN
}

export function checkOnfidoResultApi(): string {
  return API_PATHS.CHECK_ONFIDO_RESULT
}

export function getTokenPriceApi(currency: string) {
  return API_PATHS.GET_TOKEN_PRICE.replace(':currency', currency)
}

export function getCountriesApi() {
  return API_PATHS.GET_COUNTRIES
}

export function getApplePaySessionApi() {
  return API_PATHS.GET_APPLE_PAY_SESSION
}

export function getReportLocationApi() {
  return API_PATHS.REPORT_LOCATION
}
