const { localeSubpaths } = require('next/config').default().publicRuntimeConfig

export const getLanguageByCode = (localCode: string) => {
  const langs = Object.keys(localeSubpaths)

  for (const lang of langs) {
    if (localCode.includes(lang)) {
      return localeSubpaths[lang]
    }
  }

  return undefined
}
