import { Token } from 'contract/web3'

import { NetworkID } from '..'

import {
  goerliETHToken,
  goerliStableCoin,
  mainETHToken,
  mainNetStableCoin,
  rinkebyETHToken,
  rinkebyStableCoin,
} from './'

export const getEtherStableCoinMap = (
  chainId: NetworkID
): Record<string, Token> => {
  switch (chainId) {
    case NetworkID.Main:
      return mainNetStableCoin
    case NetworkID.Rinkeby:
      return rinkebyStableCoin
    case NetworkID.Goerli:
      return goerliStableCoin

    default:
      return {}
  }
}

export const getETH = (chainId: NetworkID): Token => {
  switch (chainId) {
    case NetworkID.Main:
      return mainETHToken
    case NetworkID.Rinkeby:
      return rinkebyETHToken
    case NetworkID.Goerli:
      return goerliETHToken
    default:
      return rinkebyETHToken
  }
}

export const getEtherTransferCoinMap = (
  chainId?: NetworkID
): Record<string, Token> => {
  if (!chainId) return {}
  const mapCoin = { ...getEtherStableCoinMap(chainId) }
  const eth = getETH(chainId)

  if (eth) {
    mapCoin[eth.symbol] = eth
  }

  return mapCoin
}
