import React from 'react'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import Bugsnag from 'utils/helpers/bugsnagHelper'

const maskEmailData = (value: string) => {
  const emailRegex1 = /([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g
  const emailRegex2 = /([a-zA-Z0-9._%+-]+)%40([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g

  const masked = value.replace(emailRegex1, '***@***.***')

  return masked.replace(emailRegex2, '***@***.***')
}

const maskError = (error: Record<string, any>, keys: string[]) => {
  if (!error) {
    return
  }
  for (const key in error) {
    if (keys.indexOf(key) !== -1) {
      error[key] = `[${key}]`
    }
    const value = error[key]

    if (!value) {
      continue
    }

    if (typeof value === 'string') {
      try {
        const obj = JSON.parse(value)

        maskError(obj, keys)
        error[key] = JSON.stringify(obj)
      } catch (e) {
        error[key] = maskEmailData(value)
      }
    } else if (
      (value.length > 0 && typeof value === 'object') ||
      typeof value === 'object'
    ) {
      maskError(value, keys)
    }
  }
}

Bugsnag.start({
  appVersion: process.env.VERSION,
  apiKey: process.env.BUG_SNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_STATIC_ENV,
  onError: event => {
    try {
      maskError(event, ['email', 'token'])
    } catch {}
  },
})

interface Props {
  children: React.ReactElement
}

function EmptyWrap(props: Props) {
  return props.children
}

export const ErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React) || EmptyWrap
