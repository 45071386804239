interface InboundFund {
  id: string
  txn_id: string
  deposit_address: string
  inbound_fund_request_id: string
  currency: string
  amount: string
  status: string
  confirmed: boolean
  mismatched_reason: string
  created_at: string
  txn_created_at: string
  from_address: string
}

interface DefiSwapToken {
  name: string
  symbol: string
  address: string
  amount_in: string
  balance: string
  chain_id: number
  decimals: number
  logo_uri: string
  path: string[]
}

interface DefiSwapTransaction {
  id: string
  status: string
  amount_in: string
  slippage: string
  token: DefiSwapToken
  txn_id: string
  txn_from: string
  txn_value: string
  txn_estimated_gas: string
}

export interface PayLaterPlanDetail {
  amount: string
  currency: string
  date: string
}

export enum PaymentStatus {
  pending = 'pending',
  mining = 'mining',
  succeeded = 'succeeded',
  confirming = 'confirming',
  cancelled = 'cancelled',
  failed = 'failed',
}

export enum SubDepositAddressEnum {
  ERC20 = 'ETH',
  Bitcoin = 'BTC',
  CRO_NATIVE = 'CRO_NATIVE',
}

export interface PaymentResponseType {
  id: string
  amount: number
  amount_refunded: number
  created: number
  crypto_currency: string
  crypto_amount: string
  currency: string
  customer_id?: Null<string>
  data_url: string
  description?: Null<string>
  live_mode: boolean
  metadata?: Null<Record<string, string>>
  order_id: Null<string>
  recipient: string
  refresh_disabled: boolean
  refunded: boolean
  status: PaymentStatus
  time_window: number
  remaining_time: string
  customerProvidedInfo: any
  enable_onchain_payment: Null<boolean>
  deposit_address: Null<string>
  merchant_avatar_url: string
  expired: boolean
  current_inbound_fund: Null<InboundFund>
  settled_at: number
  onchain_allowed: boolean
  ncw_connections: string[]
  cashback_rate?: string
  network_cost: string
  return_url?: Null<string>
  cancel_url?: Null<string>
  defi_swap_transaction?: DefiSwapTransaction
  captured: boolean
  experimental_features: string[]
  payment_source: Null<string>
  payment_type: string
  payment_url: string
  qr_code: string
  pay_later_qr_code: Null<string>
  resource_id: Null<string>
  resource_type: Null<string>
  sub_merchant_id: Null<string>
  sub_deposit_addresses: Null<SubDepositAddressEnum>
  allow_pay_later: Null<boolean>
}
