import React, { useEffect, useState, FC, useCallback, useMemo } from 'react'

interface PluginOption {
  name: string
  component: FC<{
    registerFallback: (registerCallBack: (callApi: any) => void) => void
  }>
}

interface Props {
  plugins: PluginOption[]
  registerService: (service: any) => void
}

const PluginWrapper: FC<Props> = ({ plugins, children, registerService }) => {
  const appService = useMemo(() => ({}), [])

  useEffect(() => {
    if (Object.keys(appService).length === plugins.length) {
      registerService(appService)
    }
  }, [appService, plugins, registerService])
  const registerFallback = useCallback(
    (name: string) => {
      return callAPI => {
        appService['$' + name] = callAPI
      }
    },
    [appService]
  )

  return (
    <>
      {plugins.map((plugin, idx) => {
        const { name, component: Component } = plugin

        return <Component registerFallback={registerFallback(name)} key={idx} />
      })}
      {children}
    </>
  )
}

export function withAppPlugins<T>(App: React.ElementType<any>, plugins) {
  return function (props) {
    const [appService, setAppService] = useState<any>()
    const registerService = useCallback(
      (service: T) => {
        setAppService(service)
      },
      [setAppService]
    )

    return (
      <PluginWrapper plugins={plugins} registerService={registerService}>
        {appService && <App {...props} appService={appService} />}
      </PluginWrapper>
    )
  }
}

export default React.memo<FC<Props>>(PluginWrapper)
