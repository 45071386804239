import { useEffect, useState, useMemo, useCallback, useRef } from 'react'

const MILLISECONDS_SECOND = 1000
const MILLISECONDS_MINUTE = 60 * MILLISECONDS_SECOND
const MILLISECONDS_HOUR = 60 * MILLISECONDS_MINUTE
const MILLISECONDS_DAY = 24 * MILLISECONDS_HOUR
const EVENT_VISIBILITY_CHANGE = 'visibilitychange'

export function pad(n, width = 2, z = '0') {
  n = n + ''

  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

/**
 * @param  {number} duration milliseconds
 * @param  {number} interval milliseconds
 * @param  {boolean} autoStart
 * @param  {func} getNow timestamp
 */
export function useCountdown({
  duration = 0, // milliseconds
  interval = 1000, // milliseconds
  autoStart = true,
  enableAutoReset = true,
  getNow = () => Date.now(), // in order to know the interval, independent of any time zone
}) {
  // effects for display
  const [totalMilliseconds, setTotalMilliseconds] = useState(0)
  const [enabled, setEnabled] = useState(false)
  const timer = useRef({
    counting: false,
    endTime: 0,
    requestId: 0,
    intervalId: 0,
  })

  /**
   * Pauses the countdown.
   */
  const pause = useCallback(() => {
    cancelAnimationFrame(timer.current.requestId)
    timer.current.requestId = 0
  }, [])

  const cancelRoundOff = useCallback(() => {
    clearInterval(timer.current.intervalId)
    timer.current.intervalId = 0
  }, [])

  const end = useCallback(() => {
    if (!timer.current.counting) {
      return
    }

    pause()
    setTotalMilliseconds(0)
    timer.current.counting = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Progresses to countdown.
   */
  const progress = useCallback(ms => {
    if (!timer.current.counting) {
      return
    }

    const newTotalMilliseconds = ms - interval

    setTotalMilliseconds(newTotalMilliseconds)

    resume(newTotalMilliseconds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Continues the countdown.
   */
  const resume = useCallback(ms => {
    if (!timer.current.counting) {
      return
    }

    const delay = Math.min(ms, interval)

    if (delay > 0) {
      let startTime
      let prevTime

      const step = currentTime => {
        if (!startTime) {
          startTime = currentTime
        }

        if (!prevTime) {
          prevTime = currentTime
        }

        const elapsed = currentTime - startTime

        if (
          elapsed >= delay ||
          // Avoid losing time about one second per minute (currentTime - prevTime ≈ 16ms)
          elapsed + (currentTime - prevTime) / 2 >= delay
        ) {
          progress(ms)
        } else {
          pause()
          timer.current.requestId = requestAnimationFrame(step)
        }

        prevTime = currentTime
      }

      timer.current.requestId = requestAnimationFrame(step)
    } else {
      end()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Starts to countdown.
   */
  const start = useCallback(ms => {
    if (timer.current.counting) {
      return
    }

    timer.current.counting = true

    setEnabled(true)

    if (document.visibilityState === 'visible') {
      resume(ms)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initCountdown = useCallback(() => {
    const newTotalMilliseconds = duration

    setTotalMilliseconds(newTotalMilliseconds)
    timer.current.endTime = getNow() + duration

    if (autoStart) {
      start(newTotalMilliseconds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const update = useCallback(() => {
    let newTotalMilliseconds = 0

    if (timer.current.counting) {
      newTotalMilliseconds = Math.max(0, timer.current.endTime - getNow())
      setTotalMilliseconds(newTotalMilliseconds)
    }

    return newTotalMilliseconds
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const roundOff = useCallback(() => {
    pause()
    resume(update())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleVisibilityChange = useCallback(() => {
    switch (document.visibilityState) {
      case 'visible':
        resume(update())
        break

      case 'hidden':
        pause()
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    initCountdown()

    document.addEventListener(EVENT_VISIBILITY_CHANGE, handleVisibilityChange)

    // just round off the time
    timer.current.intervalId = window.setInterval(() => {
      roundOff()
    }, 3000)

    return () => {
      document.removeEventListener(
        EVENT_VISIBILITY_CHANGE,
        handleVisibilityChange
      )
      pause()
      cancelRoundOff()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (enableAutoReset) {
      // restart the countdown if the duration changes
      end()
      const newTotalMilliseconds = duration

      setTotalMilliseconds(newTotalMilliseconds)
      timer.current.endTime = getNow() + duration

      start(newTotalMilliseconds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration])

  // Don't need to memoize this, because it is only low-cost computations
  const days = Math.floor(totalMilliseconds / MILLISECONDS_DAY)
  const hours = Math.floor(
    (totalMilliseconds % MILLISECONDS_DAY) / MILLISECONDS_HOUR
  )
  const minutes = Math.floor(
    (totalMilliseconds % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE
  )
  const seconds = Math.floor(
    (totalMilliseconds % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND
  )
  const milliseconds = Math.floor(totalMilliseconds % MILLISECONDS_SECOND)
  const roundedSeconds = Math.round(
    (totalMilliseconds % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND
  )
  const formatSeconds = roundedSeconds === 60 ? 59 : roundedSeconds

  const totalHours = Math.floor(totalMilliseconds / MILLISECONDS_HOUR)
  const totalMinutes = Math.floor(totalMilliseconds / MILLISECONDS_MINUTE)
  const totalSeconds = Math.floor(totalMilliseconds / MILLISECONDS_SECOND)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const actions = useMemo(() => ({ start, pause, resume }), [])

  return {
    days,
    hours,
    minutes,
    seconds,
    formatSeconds,
    milliseconds,
    totalHours,
    totalMinutes,
    totalSeconds,
    actions,
    enabled,
  }
}
