import React, { FC } from 'react'
import Head from 'next/head'
interface Props {
  publishableKey: string
}
const SdkProvider: FC<Props> = ({ children, publishableKey }) => {
  return (
    <>
      {publishableKey && (
        <Head>
          <title>Crypto.com Pay</title>
          <link rel="icon" href="/favicon.png" />
          <script src={`/sdk?publishable-key=${publishableKey}`}></script>
        </Head>
      )}
      {children}
    </>
  )
}

export default SdkProvider
