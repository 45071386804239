// percent rate
export const defaultSlippage = 0.5
// default 5 mins
export const defaultTransactionDeadline = 5
// max transaction deadline 10 mins
export const MaxTransactionDeadline = 10

export const defaultEtherGasPrices = {
  LastBlock: '',
  FastGasPrice: '110',
  ProposeGasPrice: '100',
  SafeGasPrice: '90',
}

export const defaultCronosGasPrice = '5000000000000'

export const defaultGasPrices = {
  etherGasPrice: defaultEtherGasPrices.ProposeGasPrice,
  cronosGasPrice: defaultCronosGasPrice,
}

export const cronosPriceDecimal = 12
export const etherGasPriceDecimals = 9

export const ETHERSCAN_API_URL = 'https://api.etherscan.io/api'

export const CRONOS_API_URL = 'https://evm-cronos.crypto.org'

export const defaultAmountPrecision = 6
