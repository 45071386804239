import { WithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { appWithTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { omit } from 'lodash'

import { DefaultLangEnum } from 'shared/types/Langs'
import { isMobileDevice } from 'shared/utils'
import { withAppPlugins } from 'components/PluginProvider'
import Message, { MessageApi } from 'components/common/Message'
import { SessionContext, Web3Context } from 'components/ContextProvider'
import TransitionContainer from 'components/TransitionContainer'
import Web3Provider from 'components/Web3Provider'
import 'styles/global.css'
import 'hint.css'
import 'rc-select/assets/index.css'
import {
  defaultGasPrices,
  defaultSlippage,
  defaultTransactionDeadline,
} from 'contract/constants'
import { Token } from 'contract/web3'
import DevTools from 'components/DevTools'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { setLocale } from 'utils/locale'
import { getLanguageByCode } from 'utils/i18n/languageLocal'
import { useGTM } from 'hooks/index'
import CurrencyConfigProvider from 'components/CurrencyConfigProvider'
import SdkProvider from 'components/SdkProvider'
import 'abort-controller/polyfill'
import TransportProvider from 'components/buy_crypto/TransportProvider'

export type GlobalService = {
  $message: MessageApi
}
export const globalPlugins = [
  {
    name: 'message',
    component: Message,
  },
]
function App({
  Component,
  pageProps,
  appService,
}: AppProps & WithTranslation & { pageProps: any; appService: GlobalService }) {
  useGTM()
  const [isMobile, setIsMobile] = useState<boolean>(pageProps?.isMobile)
  const [liveMode, setLiveMode] = useState(true)
  const [paymentId, setPaymentId] = useState('')
  const [publishableKey, setPublishableKey] = useState('')
  const [gasPrices, setGasPrices] = useState(defaultGasPrices)
  const [slippage, setSlippage] = useState(defaultSlippage)
  const [transactionDeadline, setTransactionDeadline] = useState(
    defaultTransactionDeadline
  )
  const [web3, setWeb3] = useState()
  const [chainId, setChainId] = useState(0)
  const [account, setAccount] = useState('')
  const [connector, setConnector] = useState()
  const [copiedKey, setCopiedKey] = useState('')
  const [sentTransaction, setSentTransaction] = useState('')
  const [paymentData, setPaymentData] = useState({})
  const [selectedToken, setSelectedToken] = useState()
  const [tokens, setTokens] = useState<Token[]>()
  const [tokenMap, setTokenMap] = useState({})

  const updatePaymentId = useCallback((id: string) => {
    setPaymentId(id)
  }, [])

  const router = useRouter()

  const {
    query: { publishableKey: key, id, defaultLang },
  } = router

  const setDefaultLang = useCallback(() => {
    const lang = getLanguageByCode(
      (defaultLang || router.locale) as DefaultLangEnum
    )

    setLocale(lang)
    // NOTE: Fixes a problem that refresh page with route changes
    // !['/button', '/banner'].includes(router.pathname) is used to hack PAY-6964
    if (
      router.query.defaultLang &&
      !['/button', '/banner'].includes(router.pathname)
    ) {
      router.replace(
        {
          pathname: router.pathname,
          query: omit(router.query, 'defaultLang'),
        },
        undefined,
        { locale: lang }
      )
    }
  }, [defaultLang, router])

  useEffect(() => {
    setDefaultLang()
  }, [defaultLang, setDefaultLang])

  useEffect(() => {
    isMobile === undefined &&
      setIsMobile(isMobileDevice(window.navigator.userAgent))
  }, [isMobile])

  useEffect(() => {
    id && id !== paymentId && setPaymentId(id as string)
    key && key !== publishableKey && setPublishableKey(key as string)
  }, [id, key, paymentId, publishableKey])

  const updatePublishableKey = useCallback((key: string) => {
    setPublishableKey(key)
  }, [])

  const sessionContext = useMemo(
    () => ({
      isMobile,
      liveMode,
      paymentId,
      setLiveMode,
      setPaymentId: updatePaymentId,
      publishableKey,
      setPublishableKey: updatePublishableKey,
      copiedKey,
      setCopiedKey,
      paymentData,
      setPaymentData,
    }),
    [
      copiedKey,
      isMobile,
      liveMode,
      paymentData,
      paymentId,
      publishableKey,
      updatePaymentId,
      updatePublishableKey,
    ]
  )

  const web3Context = useMemo(
    () => ({
      web3,
      chainId,
      account,
      connector,
      selectedToken,
      tokens,
      tokenMap,
      gasPrices,
      slippage,
      transactionDeadline,
      sentTransaction,
      setSlippage,
      setTransactionDeadline,
      setConnector,
      setWeb3,
      setGasPrices,
      setChainId,
      setAccount,
      setSelectedToken,
      setTokens,
      setTokenMap,
      setSentTransaction,
    }),
    [
      account,
      chainId,
      connector,
      gasPrices,
      selectedToken,
      sentTransaction,
      slippage,
      tokenMap,
      tokens,
      transactionDeadline,
      web3,
    ]
  )

  return (
    <ErrorBoundary>
      {router.asPath.startsWith('/buy_crypto/') ? (
        <TransportProvider>
          <TransitionContainer>
            <DevTools />
            <Component {...pageProps} {...appService} />
          </TransitionContainer>
        </TransportProvider>
      ) : (
        <CurrencyConfigProvider>
          <SessionContext.Provider
            value={{ ...appService, ...(sessionContext as any) }}
          >
            <Web3Context.Provider value={{ ...(web3Context as any) }}>
              <TransitionContainer>
                <SdkProvider publishableKey={publishableKey}>
                  <Web3Provider>
                    <DevTools />

                    <Component {...pageProps} {...appService} />
                  </Web3Provider>
                </SdkProvider>
              </TransitionContainer>
            </Web3Context.Provider>
          </SessionContext.Provider>
        </CurrencyConfigProvider>
      )}
    </ErrorBoundary>
  )
}

export default appWithTranslation(
  withAppPlugins<GlobalService>(App, globalPlugins)
)
