import Api from 'utils/api/fetchApi'

import { useLazyRequest } from './useLazyRequest'
import { usePublishableKey } from './usePublishableKey'

export interface MutationConfig {
  withToken?: boolean
  token?: string
  headers?: HeadersInit
  onValid?: (data: any) => boolean
  onCompleted?: (data: any) => void
  variables?: Record<string, unknown>
}
export function useMutation<Data>(api: RequestInfo, params?: MutationConfig) {
  const { withToken = true, token, headers, ...options } = params || {}
  const publishableKey = usePublishableKey()

  const fetcher = new Api({
    authToken: withToken ? token || publishableKey : undefined,
    headers,
  })

  const result = useLazyRequest<Data>(api, fetcher.post, options)

  return [...result, fetcher.controller] as const
}
