import React, { FC, useContext, useEffect } from 'react'
import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import Web3 from 'web3'
import { useRouter } from 'next/router'

import { getPaymentRouterQueries } from 'utils/route'
import {
  metaMask,
  hooks as metaMaskHooks,
} from 'utils/connectors/metamaskConnect'
import {
  walletConnect,
  hooks as wallectConnectHooks,
} from 'utils/connectors/wallectConnect'

import { Web3Context } from './ContextProvider'

export const isOnUnsupportedNetwork = () => {
  return false
} //error instanceof UnsupportedChainIdError

const Web3ContextProvider: FC = ({ children }) => {
  const router = useRouter()

  const {
    setAccount,
    setChainId,
    setWeb3,
    chainId: CHAIN_ID,
    account: ACCOUNT,
  } = useContext(Web3Context)
  const { account, chainId, connector, isActive } = useWeb3React()

  useEffect(() => {
    const { ethereum } = window as any

    if (ethereum) {
      const handleChanged = data => {
        if (data instanceof Array) {
          // handle account changed
          data.length > 0 && connector && connector.activate()

          return
        }
        connector && connector.activate()
      }

      //ethereum.on('connect', handleChanged)
      ethereum.on('chainChanged', handleChanged)
      ethereum.on('accountsChanged', handleChanged)

      return () => {
        // ethereum.removeListener('connect', handleChanged)
        ethereum.removeListener('chainChanged', handleChanged)
        ethereum.removeListener('accountsChanged', handleChanged)
      }
    }
  }, [connector, isActive])

  useEffect(() => {
    if (account && chainId && connector?.provider) {
      setAccount(account)
      setChainId(chainId)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      connector?.provider && setWeb3(new Web3(connector.provider))
    }
  }, [
    ACCOUNT,
    CHAIN_ID,
    account,
    chainId,
    connector.provider,
    setAccount,
    setChainId,
    setWeb3,
  ])
  useEffect(() => {
    const { useWeb3, isMetaMask, isWalletConnect } =
      getPaymentRouterQueries(router)

    if (!connector?.provider && useWeb3) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      isMetaMask && metaMask?.provider && setWeb3(new Web3(metaMask.provider))
      isWalletConnect &&
        walletConnect?.provider &&
        setWeb3(new Web3(walletConnect.provider))
    }
  }, [connector?.provider, setWeb3, router])

  return <>{children}</>
}

const SdkWeb3Provider: FC = ({ children }) => {
  return (
    <Web3ReactProvider
      connectors={[
        [metaMask, metaMaskHooks],
        [walletConnect, wallectConnectHooks],
      ]}
    >
      <Web3ContextProvider>{children}</Web3ContextProvider>
    </Web3ReactProvider>
  )
}

export default React.memo<FC>(SdkWeb3Provider)
