import { useCallback, useContext } from 'react'

import { CurrencyConfigContext } from '../components/ContextProvider'

export function useChainListForWeb3() {
  const {
    currencyConfig: { chains, tokensByNetwork, tokens },
  } = useContext(CurrencyConfigContext)

  const getChainListForWeb3 = useCallback(
    (featureFlagList?: Array<string>) =>
      chains
        .filter(chain => chain.addressType === 'ETH')
        .filter(chain => {
          if (featureFlagList && chain.featureFlag) {
            return featureFlagList.includes(chain.featureFlag)
          }

          return true
        }),
    [chains]
  )

  const isChainValid = useCallback(
    (chainId: number, featureFlagList?: Array<string>) =>
      getChainListForWeb3(featureFlagList).some(
        chain => chain.ethereumChainId === chainId
      ),
    [getChainListForWeb3]
  )

  const getChainNativeCoin = useCallback(
    (chainId: number) => {
      const tokens = Object.values(tokensByNetwork).flat()
      const chain = chains.find(c => c.ethereumChainId === chainId)

      return tokens.find(t => !t.contractAddress && t.chainId === chain?.id)
    },
    [chains, tokensByNetwork]
  )

  const getTokensByNumChainId = useCallback(
    (chainId: number) => {
      const chain = chains.find(c => c.ethereumChainId === chainId)

      if (!chain) return []
      const tokensWithNetwork = Object.values(tokensByNetwork)
        .flat()
        .filter(t => t.chainId === chain.id)
        .map(t => {
          const token = tokens.find(_token => _token.symbol === t.symbol)

          if (!token) return null

          return {
            name: t.name,
            symbol: t.symbol,
            address: t.contractAddress,
            decimals: t.decimal || token.decimal,
            chainId: chainId,
            logoUri: t.iconUrl,
            amountIn: '0',
          }
        })

      const retTokens = {}

      tokensWithNetwork.forEach(t => {
        if (t) {
          retTokens[t.symbol] = t
        }
      })

      return retTokens
    },
    [chains, tokens, tokensByNetwork]
  )

  return {
    getChainListForWeb3,
    isChainValid,
    getChainNativeCoin,
    getTokensByNumChainId,
  }
}
