import Head from 'next/head'
import React, { FC, useEffect, useState } from 'react'

import { isMobileDevice } from 'shared/utils'
import { isDev, isStaging, isTesting } from 'utils/getEnv'

const DevTools: FC = () => {
  const [needVConsole, setNeedVConsole] = useState<boolean>()

  useEffect(() => {
    const needVConsole =
      (isStaging() || isDev() || isTesting()) &&
      isMobileDevice(window.navigator.userAgent)

    if (!needVConsole) return
    setNeedVConsole(true)

    window.onload = () => {
      const VConsole = window.VConsole

      needVConsole && VConsole && new VConsole()
    }
  }, [])

  return needVConsole ? (
    <Head>
      <title>Crypto.com Pay</title>
      <link rel="icon" href="/favicon.png" />
      <script src="/lib/vconsole.min.js"></script>
    </Head>
  ) : null
}

export default DevTools
