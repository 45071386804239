import {
  BrowserConfig,
  NotifiableError,
  OnErrorCallback,
  Event,
} from '@bugsnag/browser'
import { NodeConfig } from '@bugsnag/node'
import Bugsnag from '@bugsnag/js'
import { BugsnagPluginReactResult } from '@bugsnag/plugin-react'

const start = (apiKeyOrOpts: string | BrowserConfig | NodeConfig): void => {
  if (process.env.BUG_SNAG_API_KEY) {
    Bugsnag.start(apiKeyOrOpts)
  }
}

const notify = (
  error: NotifiableError,
  onError?: OnErrorCallback,
  cb?: (err: any, event: Event) => void
): void => {
  if (process.env.BUG_SNAG_API_KEY) {
    Bugsnag.notify(error, onError, cb)
  }
}

const getPlugin = (id: 'react'): BugsnagPluginReactResult | undefined => {
  if (process.env.BUG_SNAG_API_KEY) {
    return Bugsnag.getPlugin(id)
  }
}

export default { start, notify, getPlugin }
