interface RouteConfig {
  route: string
  withTransition?: boolean
}

export const ROUTES = {
  button: '/button',
  banner: '/banner',
  checkout: '/checkout',
  invoice: '/invoice',
  subscription: '/subscription',
  purchase: '/purchase',
  setEmail: '/set_email',
  setWallet: '/set_wallet',
  confirming: '/confirming',
  onchainOutbound: '/onchain_outbound',
  onchainOutboundToOffchain: '/onchain_outbound/toOffchain',
  onchainOutboundToOnchain: '/onchain_outbound/toOnchain',
  onchainOutboundInfo: '/onchain_outbound/outboundInfo',
  download: '/download',
  buyCrypto: '/buy_crypto',
  buyCryptoSetEmail: '/buy_crypto/set_email',
  buyCryptoError: '/buy_crypto/error',
  notFound: '/404',
  error: '/error',
  test: '/test',
}

export const routeConfig: Array<RouteConfig> = [
  {
    route: ROUTES.button,
    withTransition: false,
  },
  {
    route: ROUTES.checkout,
    withTransition: true,
  },
  {
    route: ROUTES.invoice,
    withTransition: true,
  },
  {
    route: ROUTES.subscription,
    withTransition: true,
  },
  {
    route: ROUTES.purchase,
    withTransition: true,
  },
  {
    route: ROUTES.confirming,
    withTransition: true,
  },
  {
    route: ROUTES.download,
    withTransition: true,
  },
  {
    route: ROUTES.setEmail,
    withTransition: true,
  },
  {
    route: ROUTES.setWallet,
    withTransition: true,
  },
  {
    route: ROUTES.onchainOutbound,
    withTransition: true,
  },
  {
    route: ROUTES.onchainOutboundInfo,
    withTransition: true,
  },
  {
    route: ROUTES.onchainOutboundToOffchain,
    withTransition: true,
  },
  {
    route: ROUTES.onchainOutboundToOnchain,
    withTransition: true,
  },
  {
    route: ROUTES.notFound,
    withTransition: false,
  },
  {
    route: ROUTES.test,
    withTransition: false,
  },
  {
    route: ROUTES.error,
    withTransition: false,
  },
  {
    route: ROUTES.banner,
    withTransition: false,
  },
  {
    route: ROUTES.buyCrypto,
    withTransition: false,
  },
  {
    route: ROUTES.buyCryptoSetEmail,
    withTransition: false,
  },
  {
    route: ROUTES.buyCryptoError,
    withTransition: false,
  },
]
