import * as mathjs from 'mathjs'

export const formatBigNumber = (
  value: string | null,
  decimals: number
): string =>
  mathjs
    .format(mathjs.divide(mathjs.bignumber(value), 10 ** decimals), {
      notation: 'fixed',
    })
    .toString()

export const formatNumber = (number: string | number, precision?: number) => {
  const numStr = mathjs
    .format(mathjs.bignumber(number), { notation: 'fixed' })
    .toString()
  const numArr = numStr.split('.')

  if (numArr.length === 2 && precision) {
    let zeroCount = 0

    while (zeroCount < numArr[1].length && numArr[1][zeroCount] === '0') {
      zeroCount += 1
    }

    return `${numArr[0]}.${numArr[1].substr(0, precision + zeroCount)}`
  }

  return numStr
}

export const ellipsisAddress = (address: string): string => {
  const length = address.length

  return `${address.substring(0, 6)}...${address.substring(length - 4, length)}`
}

export const formatStringWithSingleSpace = (str: string) =>
  str.replace(/\s{2,}/g, ' ')

export function toFixedTrunc(x: number | string, n = 0) {
  const v = (typeof x === 'string' ? x : x.toString()).split('.')

  if (n <= 0) return v[0]

  let f = v[1] || ''

  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`
  while (f.length < n) f += '0'

  return `${v[0]}.${f}`
}
