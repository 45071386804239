import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

export const [walletConnect, hooks] = initializeConnector<WalletConnectV2>(
  actions =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: 'ff8c92a7709070dd1343d05dcf4fd270',
        showQrModal: true,
        chains: [1],
      },
    })
)
