import { NetworkID } from '..'
import { Token } from '../web3'

import {
  CRO_Cronos_Test,
  CRO_Cronos_Main,
  CronosMainNetTransferCoins,
  CronosTestNetTransferCoins,
} from './'

export const getCronosCRO = (chainId: NetworkID) => {
  return chainId === NetworkID.Cronos_test ? CRO_Cronos_Test : CRO_Cronos_Main
}

export const getCronosTransferCoinMap = (
  chainId: NetworkID
): Record<string, Token> => {
  switch (chainId) {
    case NetworkID.Cronos_test:
      return CronosTestNetTransferCoins
    case NetworkID.Cronos:
      return CronosMainNetTransferCoins
    default:
      return CronosTestNetTransferCoins
  }
}
