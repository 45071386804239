import { Token } from 'contract/web3'

export const PolygonMainNetTransferCoins: Record<string, Token> = {
  USDC: {
    address: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
    amountIn: '0',
    chainId: 25,
    decimals: 18,
    logoUri: '/images/USDC.png',
    name: 'USDC',
    symbol: 'USDC',
    balance: '0',
  },
}
