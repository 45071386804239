import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'

import { getLatestVersion } from 'utils/api'
import { isDev } from 'utils/getEnv'
// import { CSSTransition } from 'react-transition-group'
import { routeConfig } from 'utils/route'

const TransitionContainer: FC = ({ children }) => {
  const router = useRouter()
  const [loading, setLoading] = useState(false)

  const currentRouteConfig = routeConfig.find(
    ({ route }) => route === router.route
  )

  const needTransition = currentRouteConfig
    ? currentRouteConfig.withTransition
    : false

  useEffect(() => {
    const routeChangeStartHandler = async () => {
      setLoading(true)
      const latestVersion = await getLatestVersion()

      if (latestVersion.buildId !== process.env.BUILD_ID && !isDev()) {
        router.reload()
      }
    }

    const routeChangeCompleteHandler = () => {
      setLoading(false)
    }

    router.events.on('routeChangeStart', routeChangeStartHandler)
    router.events.on('routeChangeComplete', routeChangeCompleteHandler)

    return () => {
      router.events.off('routeChangeStart', routeChangeStartHandler)
      router.events.off('routeChangeComplete', routeChangeCompleteHandler)
    }
  }, [router])

  // ADD some animations
  return needTransition ? (
    <main className="main_container sm:pt-lg">
      {/* <CSSTransition
        in={!loading}
        timeout={200}
        classNames="fade"
        unmountOnExit={false}
      > */}
      <div
        className={`w-full flex flex-col items-center ${
          loading ? 'page-loading' : ''
        }`}
      >
        {children}
        <style jsx>{`
          @media (min-width: 601px) {
            .fade-enter {
              transform: translateY(10%);
            }
            .fade-enter-active {
              transform: translateY(0%);
            }
            .fade-exit {
              transform: translateY(0%);
            }
            .fade-exit-active {
              transform: translateY(0%);
            }
          }

          .fade-enter-active,
          .fade-exit-active {
            transition: opacity 500ms, transform 500ms;
          }
        `}</style>
      </div>
      {/* </CSSTransition> */}
    </main>
  ) : (
    <>{children}</>
  )
}

export default TransitionContainer
