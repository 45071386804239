import { addZero } from './helper'

export const transferSecondsToMinutes = (seconds: number): string => {
  return `${addZero(Math.floor(seconds / 60))}: ${addZero(seconds % 60)}`
}

export const getSecondsFromMinutes = (time: string): number => {
  try {
    const [minute, seconds]: number[] = time
      .split(':')
      .map((x: string): number => Number(x))

    return minute * 60 + seconds
  } catch (error) {
    return 0
  }
}
