import { PaymentCryptoCurrencyEnum } from 'constants/utils'

import { getEtherTransferCoinMap } from './erc20'
import { Token } from './web3'
import { CroAddress, WcroAddress } from './cro'
import {
  CRO_Cronos_Main,
  CRO_Cronos_Test,
  getCronosTransferCoinMap,
} from './cronos'
import { getPolygonTransferCoinMap } from './polygon/methods'

import { NetworkID } from './index'

export const isMainNet = (chainId: NetworkID) =>
  chainId === NetworkID.Main ||
  chainId === NetworkID.Cronos ||
  chainId === NetworkID.BSC ||
  chainId === NetworkID.Polygon

export const isEthereum = (chainId?: NetworkID) =>
  chainId === NetworkID.Main ||
  chainId === NetworkID.Rinkeby ||
  chainId === NetworkID.Ropsten ||
  chainId === NetworkID.Goerli

export const isCronos = (chainId?: NetworkID) =>
  chainId === NetworkID.Cronos_test || chainId === NetworkID.Cronos

export const isPolygon = (chainId?: NetworkID) => chainId === NetworkID.Polygon

// Tokens that does NOT need Swap
export const getTransferCoinMap = (
  chainId?: NetworkID
): Record<string, Token> => {
  if (!chainId) return {}
  if (isEthereum(chainId)) return getEtherTransferCoinMap(chainId)
  if (isCronos(chainId)) return getCronosTransferCoinMap(chainId)
  if (isPolygon(chainId)) return getPolygonTransferCoinMap(chainId)

  // TODO
  // need to add judgment for Cronos/BSC/Polygon
  return {}
}

export const isCronosCro = (token: Token) =>
  token.address === CRO_Cronos_Main.address &&
  (token.symbol === CRO_Cronos_Main.symbol ||
    token.symbol === CRO_Cronos_Test.symbol)

export const isNonCronosCro = (token: Token) =>
  !isCronosCro(token) && token.symbol === PaymentCryptoCurrencyEnum.CRO

// please add new CRO token when you want to support a new chain
export const getCroToken = (chainId: NetworkID): Token | null => {
  const currentChainId = chainId

  // Ethereum CRO token
  const baseCROToken = {
    name: 'CRO',
    symbol: 'CRO',
    logoUri: '/images/CRO.png',
    chainId: currentChainId,
  }

  switch (currentChainId) {
    case NetworkID.Rinkeby:
      return {
        ...baseCROToken,
        decimals: 8,
        address: CroAddress.Rinkeby,
      }
    case NetworkID.Ropsten:
      return {
        ...baseCROToken,
        decimals: 8,
        address: CroAddress.Ropsten,
      }
    case NetworkID.Goerli:
      return {
        ...baseCROToken,
        decimals: 18,
        address: CroAddress.Goerli,
      }
    case NetworkID.Cronos_test:
      return CRO_Cronos_Test
    case NetworkID.Cronos:
      return CRO_Cronos_Main
    default:
      return null
  }
}

export const getWcroToken = (chainId: NetworkID) => {
  const baseWcro = {
    name: 'WCRO',
    symbol: 'WCRO',
    logoUri: '/images/CRO.png',
    chainId,
    decimals: 8,
  }

  switch (chainId) {
    case NetworkID.Cronos:
      return {
        ...baseWcro,
        address: WcroAddress.Cronos,
        decimals: 18,
      }
    case NetworkID.Cronos_test:
      return {
        ...baseWcro,
        address: WcroAddress.CronosTest,
        decimals: 18,
      }
    default:
      return {
        ...baseWcro,
        address: WcroAddress.CronosTest,
      }
  }
}

export const getCroSwapAddress = (chainId: NetworkID) => {
  if (isCronos(chainId)) return getWcroToken(chainId).address

  return getCroToken(chainId)?.address || ''
}
