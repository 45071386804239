interface Controller {
  signal: AbortSignal
  abort: () => void
}
export const getController = (): Controller => {
  let controller = new AbortController()
  const set = (ctl: AbortController) => {
    controller = ctl
  }
  const reset = () => {
    set(new AbortController())
  }

  const result = {
    signal: controller.signal,
    abort: () => {
      controller.abort()
      reset()
    },
  }

  Object.defineProperty(result, 'signal', {
    get() {
      return controller.signal
    },
  })

  return result
}
