import { getPaymentData } from './getApiData'

export const parseCreatePaymentError = (id: string) => {
  let error = {
    errorStatusCode: null,
    errorName: null,
    arguments: null,
  }

  try {
    error = JSON.parse(id) || error
  } catch (error) {}

  return error
}

export const getPaymentDataOnServerSide = async (
  publishableKey: string,
  id: string,
  headers?: HeadersInit
): Promise<any> => {
  const {
    errorStatusCode,
    errorName,
    arguments: errorArguments,
  } = parseCreatePaymentError(id)

  if (errorStatusCode || errorName) {
    return {
      error: {
        statusCode: errorStatusCode,
        errorName,
        arguments: errorArguments,
      },
    }
  }

  return getPaymentData(publishableKey, id, headers)
}
