import React from 'react'
import Web3 from 'web3'
import { Connector } from '@web3-react/types'

import { GlobalService } from 'pages/_app'
import { Token, TokenMap } from 'contract/web3'
import {
  defaultGasPrices,
  defaultSlippage,
  defaultTransactionDeadline,
} from 'contract/constants'

export interface GasPrices {
  etherGasPrice: string
  cronosGasPrice: string
}

interface PaymentData {
  experimentalFeatures?: Array<string>
  ncwConnections?: Array<string>
  subDepositAddresses?: Record<string, string>
}

export type Session = {
  isMobile?: boolean
  liveMode: boolean
  paymentId: string
  publishableKey: string
  setLiveMode: (isLiveMode: boolean) => void
  setPaymentId: (id: string) => void
  setPublishableKey: (publishableKey: string) => void
  copiedKey: string | symbol
  setCopiedKey: (key: string | symbol) => void
  paymentData: PaymentData
  setPaymentData: (paymentData: PaymentData) => void
}

export type Web3Session = {
  gasPrices: GasPrices
  chainId: number
  account: string
  web3?: Web3
  connector?: Connector
  slippage: number
  transactionDeadline: number
  selectedToken: Token | null
  tokens?: Token[]
  tokenMap?: TokenMap
  sentTransaction?: string
  setSlippage: (slippage: number) => void
  setTransactionDeadline: (transactionDeadline: number) => void
  setWeb3: (web3: Web3) => void
  setConnector: (connector: Connector) => void
  setGasPrices: (gasPrices: GasPrices) => void
  setChainId: (chainId: number) => void
  setAccount: (account: string) => void
  setSelectedToken: (token: Token | null) => void
  setTokens: (tokens: Token[]) => void
  setTokenMap: (tokenMap: TokenMap) => void
  setSentTransaction: (txHash: string) => void
}

export interface Chain {
  id: string
  name: string
  symbol: string
  networkType: string
  addressType: string
  ifUseDestinationTag: boolean
  ethereumChainId: number
  featureFlag: string
  explorerUrls: {
    tx: string
    address: string
  }
  processingTime: number
  explorerUrl: string
  rpcUrl: string
  nativeCurrency: {
    symbol: string
    name: string
    decimals: number
  }
}

export interface BaseToken {
  name: string
  symbol: string
  isPricingCurrency: boolean
  decimal: number
  precision: number
  iconUrl: Null<string>
}

export interface Fiat {
  symbol: string
  precision: number
}

export interface BaseTokenNetwork {
  name: string
  symbol: string
  symbolWithNetwork: string
  contractAddress: string
  chainId: string
  displayChainName: string
  ncwAbility?: string[]
  featureFlag?: string[]
  displayOrder: number
  iconUrl: Null<string>
  decimal: number
}

export type CurrencyConfig = {
  chains: Chain[]
  tokens: BaseToken[]
  fiats: Fiat[]
  tokensByNetwork: Record<string, BaseTokenNetwork[]>
}

export type CurrencyConfigContextType = {
  currencyConfig: CurrencyConfig
  setCurrencyConfig: (config: CurrencyConfig) => void
}

export const defaultSessionContext: GlobalService & Session = {
  liveMode: true,
  paymentId: '',
  publishableKey: '',
  $message: () => {},
  setLiveMode: () => {},
  setPaymentId: () => {},
  setPublishableKey: () => {},
  copiedKey: '',
  setCopiedKey: () => {},
  paymentData: {
    experimentalFeatures: [],
    subDepositAddresses: {},
    ncwConnections: [],
  },
  setPaymentData: () => {},
}
export const SessionContext = React.createContext(defaultSessionContext)

export const defaultWeb3Context: Web3Session = {
  chainId: 0,
  account: '',
  selectedToken: null,
  gasPrices: defaultGasPrices,
  slippage: defaultSlippage,
  transactionDeadline: defaultTransactionDeadline,
  setSlippage: () => {},
  setTransactionDeadline: () => {},
  setConnector: () => {},
  setWeb3: () => {},
  setGasPrices: () => {},
  setChainId: () => {},
  setAccount: () => {},
  setSelectedToken: () => {},
  setTokens: () => {},
  setTokenMap: () => {},
  setSentTransaction: () => {},
}

export const Web3Context = React.createContext(defaultWeb3Context)

export const defaultCurrencyConfigContext: CurrencyConfigContextType = {
  currencyConfig: {
    chains: [],
    tokens: [],
    fiats: [],
    tokensByNetwork: {},
  },
  setCurrencyConfig: () => {},
}

export const CurrencyConfigContext = React.createContext(
  defaultCurrencyConfigContext
)
