import { ExperimentalFeatures } from 'constants/utils'
import { MismatchReasons } from 'components/payment/MismatchedReason'
import { PaymentStatus } from 'shared/types/payment'

import { getSecondsFromMinutes } from '../helpers'

export const getHandlePaymentData = (
  payment: any /** TODO: replace any to PaymentResponseType(shared/types/payment) */
) => {
  const merchantAvatarUrl = payment?.merchant_avatar_url

  const isLiveMode = payment?.live_mode
  const isSandbox = !isLiveMode

  const status = payment?.status

  const resourceId = payment?.resource_id
  const orderId = payment?.order_id
  const recipient = payment?.recipient
  const amount = payment?.original_amount
  const currency = payment?.currency
  const cryptoAmount = payment?.crypto_amount

  const cryptoCurrency = payment?.crypto_currency
  const cashbackRate = payment?.cashback_rate

  const dataUrl = payment?.data_url
  const erc20Address = payment?.deposit_address
  const qrCodeJsonStr = payment?.qr_code
  const experimentalFeatures: Array<ExperimentalFeatures> =
    payment?.experimental_features || []
  const hasPaymentJsonQrcodeFeature = experimentalFeatures.includes(
    ExperimentalFeatures.payment
  )

  const subDepositAddresses = payment?.sub_deposit_addresses
  const croNativeAddress = subDepositAddresses?.CRO_NATIVE

  // has onchain feature flag?
  const enableOnchainPayment = payment?.enable_onchain_payment
  // has provided customer email?
  const onchainAllowed = payment?.onchain_allowed

  const ncwConnections = payment?.ncw_connections || []

  const resource = payment?.resource
  const expiredAt = resource?.expired_at || payment?.expired_at
  const customerEmail = resource?.customer?.email
  const customerProvidedEmail = payment?.customer_provided_info?.email

  const paymentStatus = payment?.status
  const isApproved = paymentStatus === PaymentStatus.succeeded

  const currentInboundFundStatus = payment?.current_inbound_fund?.status
  const isConfirming =
    currentInboundFundStatus === PaymentStatus.confirming ||
    currentInboundFundStatus === PaymentStatus.mining
  const currentInboundFund = payment?.current_inbound_fund
  const confirmed = currentInboundFund?.confirmed

  const mismatchedReason = currentInboundFund?.mismatched_reason

  const isDelayed = mismatchedReason === MismatchReasons.delayed

  const isSucceed = isApproved

  const isIncludingTax = !!payment?.tax?.name && !!payment?.tax?.amount

  const isCancelledFunc = () =>
    status === PaymentStatus.cancelled ||
    (status === PaymentStatus.pending &&
      expiredAt &&
      expiredAt * 1000 <= new Date().getTime() + 1000)

  const isCancelled = isCancelledFunc()

  const isFailed = status === PaymentStatus.failed

  const txnId =
    payment?.defi_swap_transaction?.txn_id ||
    payment?.current_inbound_fund?.txn_id

  const paidCurrency = payment?.current_inbound_fund?.currency

  const returnUrl = decodeURIComponent(payment?.return_url)
  const cancelUrl = decodeURIComponent(payment?.cancel_url)

  const networkCost = payment?.network_cost

  // custom expired timestamp
  const isCustomExpireTime = !!payment?.expired_at
  const isExpired = payment?.expired
  const remainingTime = payment?.remaining_time
  const remainingTimeSeconds = remainingTime
    ? getSecondsFromMinutes(remainingTime)
    : 0

  const refreshDisabled = Boolean(payment?.refresh_disabled || isCancelled)

  const isAllowPayLater = payment?.allow_pay_later
  const payLaterQrCodeStr = payment?.pay_later_qr_code

  return {
    isLiveMode,
    isSandbox,
    merchantAvatarUrl,
    resourceId,
    orderId,
    resource,
    recipient,
    amount,
    currency,
    cryptoAmount,
    cryptoCurrency,
    cashbackRate,
    dataUrl,
    subDepositAddresses,
    erc20Address,
    qrCodeJsonStr,
    experimentalFeatures,
    hasPaymentJsonQrcodeFeature,
    croNativeAddress,
    enableOnchainPayment,
    onchainAllowed,
    ncwConnections,
    isExpired,
    expiredAt,
    customerEmail,
    customerProvidedEmail,
    isApproved,
    isConfirming,
    confirmed,
    mismatchedReason,
    isSucceed,
    isDelayed,
    isCancelled,
    isFailed,
    txnId,
    refreshDisabled,
    paidCurrency,
    returnUrl,
    cancelUrl,
    networkCost,
    remainingTime,
    remainingTimeSeconds,
    isCustomExpireTime,
    currentInboundFund: {
      mismatchedReason,
      txnId,
      status,
    },
    isAllowPayLater,
    payLaterQrCodeStr,
    isCancelledFunc,
    isIncludingTax,
  }
}
