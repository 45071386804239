import { useMemo, useContext } from 'react'

import { Web3Context } from 'components/ContextProvider'
import { Token } from 'contract/web3'
import { useQuery } from 'hooks/useQuery'

import { useChainListForWeb3 } from './useChainListForWeb3'

export function useTransferCoins(dataUrl: string) {
  const { chainId } = useContext(Web3Context)
  const { getChainNativeCoin, getTokensByNumChainId } = useChainListForWeb3()

  const nativeCoin = useMemo(
    () => getChainNativeCoin(chainId),
    [chainId, getChainNativeCoin]
  )

  const urlObj = new URL('', dataUrl)
  const { data, loading, error } = useQuery(urlObj.pathname)

  const cryptoAmounts = useMemo(() => data?.['meta']?.crypto_amounts, [data])

  const stableCoinAmounts = useMemo(
    () => (cryptoAmounts ? Object.keys(cryptoAmounts) : null),
    [cryptoAmounts]
  )

  const transferCoinMap = useMemo(
    () => getTokensByNumChainId(chainId),
    [chainId, getTokensByNumChainId]
  )

  const transferCoins = useMemo(() => {
    if (!stableCoinAmounts) return null
    const coins: Token[] = []

    stableCoinAmounts.forEach(symbol => {
      const coin = transferCoinMap[symbol]
        ? {
            ...transferCoinMap[symbol],
            amountIn: cryptoAmounts[symbol],
            isNative:
              !transferCoinMap[symbol].address && nativeCoin?.symbol === symbol,
          }
        : null

      coin && coins.push(coin)
    })

    return coins
  }, [cryptoAmounts, nativeCoin?.symbol, stableCoinAmounts, transferCoinMap])

  return {
    transferCoins,
    loading,
    error,
  }
}
