export enum DefaultLangEnum {
  en = 'en',
  es = 'es-ES',
  fr = 'fr-CA',
  it = 'it-IT',
  pl = 'pl-PL',
  pt = 'pt-PT',
  ro = 'ro-RO',
  ru = 'ru-RU',
  tr = 'tr-TR',
  vi = 'vi',
  zh = 'zh-TW',
}
