import {
  ENV,
  PROTOCOLS,
  API_PROTOCOLS,
  SDK_HOSTS,
  API_HOSTS,
} from './constants'

export function isLocal(): boolean {
  return (
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === ENV.DEV ||
    process.env.NODE_ENV === ENV.TEST
  )
}

export function isTest(): boolean {
  return !!process.env.NODE_ENV && process.env.NODE_ENV === ENV.TEST
}

export function getProtocol(env: ENV): string {
  return PROTOCOLS[env]
}

export function getApiProtocol(env: ENV): string {
  return API_PROTOCOLS[env]
}

export function getSDKHost(env: ENV): string {
  return process.env.SDK_HOST || SDK_HOSTS[env]
}

export function getAPIHost(env: ENV): string {
  return process.env.API_HOST || API_HOSTS[env]
}

export function getEnv(env: ENV): ENV | string {
  return process.env.ENV || env
}

export function isLedger(userAgent: string): boolean {
  return userAgent.match(/Ledger/i) !== null
}

export function isMobileDevice(userAgent: string): boolean {
  return (
    userAgent.match(
      /Android|webOS|iPhone|iPad|iPod|bada|Symbian|Palm|CriOS|BlackBerry|IEMobile|WindowsMobile|Opera Mini/i
    ) !== null
  )
}

export function isIOS(userAgent = '') {
  return /(iPhone|iPad|iPod|iOS)/i.test(userAgent)
}

export function isAndroid(userAgent = '') {
  return /(Android)/i.test(userAgent)
}

export const getLastMinToday = (): number => {
  const todayYear = new Date().getFullYear()
  const todayMonth = new Date().getMonth()
  const todayDay = new Date().getDate()

  return new Date(todayYear, todayMonth, todayDay, 23, 59, 59).getTime()
}
