import React, { FC, useCallback, useContext } from 'react'
import {
  WithTranslation,
  withTranslation as _withTranslation,
  i18n,
} from 'next-i18next'
import { TOptions, TFunction } from 'i18next'

import { SessionContext } from './ContextProvider'
import PureContainerFC from './PureContainerFC'

const EN_TRANSLATE = i18n ? i18n.getFixedT('en') : () => {}

export function withTranslation<T = Record<string, never>>(ns?: string) {
  return function (Com): FC<T> {
    const withI18nFunc = _withTranslation(ns)
    const ComWithI18n: FC<WithTranslation & T> = ({ t, children, ...rest }) => {
      const { $message } = useContext(SessionContext)

      const $t: TFunction = useCallback(
        (key: string, data: any = {}, options?: TOptions) => {
          return t(key, data, options) || EN_TRANSLATE(key)
        },
        [t]
      )

      return (
        <PureContainerFC
          component={Com}
          t={t}
          $message={$message}
          $t={$t}
          {...rest}
        >
          {children}
        </PureContainerFC>
      )
    }

    return withI18nFunc(ComWithI18n) as FC<T>
  }
}
