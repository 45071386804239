import { useState, useEffect, useCallback, useMemo } from 'react'
import useSWR, { keyInterface, ConfigInterface, mutate } from 'swr'
import { isEqual } from 'lodash'
import { fetcherFn } from 'swr/dist/types'

import { Logger } from 'utils/helpers'
import { HttpError, ResponseData } from 'utils/api'

export function useFetch<Data>(
  key: keyInterface,
  fn?: fetcherFn<ResponseData<Data>>,
  config?: ConfigInterface
) {
  const [loading, setLoading] = useState(true)
  const [refreshInterval, setRefreshInterval] = useState(
    config?.refreshInterval
  )
  const { data, error, isValidating } = useSWR<ResponseData<Data>, HttpError>(
    key,
    fn,
    {
      compare: (a, b) => isEqual(a, b),
      onError: err => {
        Logger.logError('[Http error]: ', err)
      },
      ...config,
      refreshInterval,
    }
  )

  useEffect(() => {
    if (!data || isValidating) {
      !loading && setLoading(true)
    } else {
      loading && setLoading(false)
    }
  }, [data, isValidating, loading])

  const refetch = useCallback(() => {
    mutate(key)
  }, [key])

  const closeRefreshInterval = useCallback(() => {
    setRefreshInterval(0)
  }, [])

  const returnValue = useMemo(() => {
    return {
      loading,
      refetch,
      closeRefreshInterval,
      data: data?.error ? undefined : data,
      error: data?.error ?? error,
    }
  }, [loading, refetch, closeRefreshInterval, data, error])

  return returnValue
}
