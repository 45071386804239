import { Token } from '../web3'

export * from './methods'

export const CRO_Cronos_Main: Token = {
  address: '0x0000000000000000000000000000000000000000',
  amountIn: '0',
  chainId: 25,
  decimals: 18,
  logoUri: '/images/CRO.png',
  name: 'CRO',
  symbol: 'CRO',
  balance: '0',
  isNative: true,
}

export const CRO_Cronos_Test: Token = {
  address: '0x0000000000000000000000000000000000000000',
  amountIn: '0',
  chainId: 338,
  decimals: 18,
  logoUri: '/images/CRO.png',
  name: 'CRO',
  symbol: 'CRO',
  balance: '0',
  isNative: true,
}

export const CronosMainNetStableCoins: Record<string, Token> = {
  USDC: {
    name: 'USDCoin',
    address: '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
    symbol: 'USDC',
    decimals: 6,
    chainId: 1,
    logoUri: '/images/USDC.png',
    amountIn: '0',
  },
  /*
  TUSD: {
    name: 'TrueUSD',
    address: '0x87EFB3ec1576Dec8ED47e58B832bEdCd86eE186e',
    symbol: 'TUSD',
    decimals: 18,
    chainId: 25,
    logoUri:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0000000000085d4780B73119b644AE5ecd22b376/logo.png',
    amountIn: '0',
  },
  */
}

export const CronosTestNetStableCoins: Record<string, Token> = {
  USDC: {
    name: 'USDCoin',
    address: '0x71A26A5090fe4FeD65a8cdb11D761E6D07Ad7d36',
    symbol: 'USDC',
    decimals: 18,
    chainId: 338,
    logoUri: '/images/USDC.png',
    amountIn: '0',
  },
  /*
  TUSD: {
    name: 'TrueUSD',
    address: '0x41315E63d0663Ce839931fed7451084613E385b3',
    symbol: 'TUSD',
    decimals: 18,
    chainId: 338,
    logoUri:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0000000000085d4780B73119b644AE5ecd22b376/logo.png',
    amountIn: '0',
  },
  */
}

export const CronosMainNetTransferCoins: Record<string, Token> = {
  ...CronosMainNetStableCoins,
  ETH: {
    address: '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
    amountIn: '0',
    chainId: 25,
    decimals: 18,
    logoUri: '/images/ETH.png',
    name: 'Ether',
    symbol: 'ETH',
    balance: '0',
  },
}

export const CronosTestNetTransferCoins: Record<string, Token> = {
  ...CronosTestNetStableCoins,
  ETH: {
    address: '0x796135E94527c38433e9c42f4Cd91ca931E5e6A6',
    amountIn: '0',
    chainId: 338,
    decimals: 18,
    logoUri: '/images/ETH.png',
    name: 'Ether',
    symbol: 'ETH',
    balance: '0',
  },
}
