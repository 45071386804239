export enum NcwAbilityEnum {
  defiSwap = 'defi_swap',
  ncwMetamaskPlugin = 'ncw_metamask_plugin',
  ncwWalletConnect = 'ncw_wallet_connect',
  croChain = 'cro_chain',
}

export function hasDefiSwap(abilities: string[] = []) {
  return abilities.includes(NcwAbilityEnum.defiSwap)
}

export function hasMetamask(abilities: string[] = []) {
  return abilities.includes(NcwAbilityEnum.ncwMetamaskPlugin)
}

export function hasWalletConnect(abilities: string[] = []) {
  return abilities.includes(NcwAbilityEnum.ncwWalletConnect)
}

export function hasCroChain(abilities: string[] = []) {
  return abilities.includes(NcwAbilityEnum.croChain)
}

export function hasWeb3Ability(abilities: string[]) {
  return (
    hasDefiSwap(abilities) ||
    hasMetamask(abilities) ||
    hasWalletConnect(abilities)
  )
}
