import { isDev, isProduction, isTesting } from '../getEnv'

export const logOnDev = (...args) => {
  if (!isDev() && !isTesting) return
  console.log(...args)
}

export const logOnNonProd = (...args) => {
  if (isProduction()) return
  console.log(...args)
}

export const logError = (...args) => {
  console.error('[ERROR]: ', ...args)
}

export const Logger = {
  logOnDev,
  logOnNonProd,
  logError,
  logErrorOnDev: (...args) => {
    logError(...args)
  },
}
