import format from 'date-fns-tz/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import * as mathjs from 'mathjs'

import { Api, getSystemMaintenanceApi } from './api'

export async function getSystemMaintenanceTime(): Promise<string> {
  const uri = getSystemMaintenanceApi()
  const fetcher = new Api()
  const maintenanceTime = (await fetcher.get(uri)) || {}
  const startTime = maintenanceTime.start_time
  const endTime = maintenanceTime.end_time

  if (!startTime || !endTime) return ''

  const startTimeMoment = new Date(mathjs.multiply(startTime, 1000))
  const startYear = startTimeMoment.getUTCFullYear()
  const startMonth = startTimeMoment.getUTCMonth()
  const startDate = startTimeMoment.getUTCDate()
  const startHour = startTimeMoment.getUTCHours()
  const startMinute = startTimeMoment.getUTCMinutes()
  const startUtcMoment = utcToZonedTime(
    Date.UTC(startYear, startMonth, startDate, startHour, startMinute),
    'UTC'
  )

  const endTimeMoment = new Date(mathjs.multiply(endTime, 1000))
  const endYear = endTimeMoment.getUTCFullYear()
  const endMonth = endTimeMoment.getUTCMonth()
  const endDate = endTimeMoment.getUTCDate()
  const endHour = endTimeMoment.getUTCHours()
  const endMinute = endTimeMoment.getUTCMinutes()
  const endUtcMoment = utcToZonedTime(
    Date.UTC(endYear, endMonth, endDate, endHour, endMinute),
    'UTC'
  )

  const isSameDate =
    startYear === endYear && startMonth === endMonth && startDate === endDate

  if (isSameDate) {
    return `from ${format(startUtcMoment, 'HH: mm aaa')} to ${format(
      endUtcMoment,
      'HH: mm aaa'
    )} (UTC) on ${format(startUtcMoment, 'do')} ${format(
      startUtcMoment,
      'MMM'
    )}`
  } else {
    return `from ${format(
      startUtcMoment,
      'MMM do yyyy, HH: mm aaa'
    )} to ${format(endUtcMoment, 'MMM do yyyy, HH: mm aaa')} (UTC)`
  }
}
