import { Token } from '../web3'

export * from './methods'
export * from './erc20ABI'
export * from './methods'
export * from './erc20ABI'

export const mainETHToken: Token = {
  address: '0x0000000000000000000000000000000000000000',
  amountIn: '0',
  chainId: 1,
  decimals: 18,
  logoUri: '/images/ETH.png',
  name: 'Ether',
  symbol: 'ETH',
  balance: '0',
}

export const rinkebyETHToken: Token = {
  address: '0x0000000000000000000000000000000000000000',
  amountIn: '0',
  chainId: 4,
  decimals: 18,
  logoUri: '/images/ETH.png',
  name: 'Ether',
  symbol: 'ETH',
  balance: '0',
}

export const goerliETHToken: Token = {
  address: '0x0000000000000000000000000000000000000000',
  amountIn: '0',
  chainId: 5,
  decimals: 18,
  logoUri: '/images/ETH.png',
  name: 'Ether',
  symbol: 'ETH',
  balance: '0',
}

export const mainNetStableCoin: Record<string, Token> = {
  USDC: {
    name: 'USDCoin',
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    symbol: 'USDC',
    decimals: 6,
    chainId: 1,
    logoUri: '/images/USDC.png',
    amountIn: '0',
  },
  /*
  TUSD: {
    address: '0X0000000000085D4780B73119B644AE5ECD22B376',
    amountIn: '0',
    chainId: 1,
    decimals: 18,
    logoUri:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0000000000085d4780B73119b644AE5ecd22b376/logo.png',
    name: 'TrueUSD',
    symbol: 'TUSD',
  },
  */
}

export const rinkebyStableCoin: Record<string, Token> = {
  /*
  TUSD: {
    address: '0x46c1ebb6bec882e92e52982a33eaf44db3bfde26',
    amountIn: '0',
    chainId: 4,
    decimals: 18,
    logoUri:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0000000000085d4780B73119b644AE5ecd22b376/logo.png',
    name: 'TrueUSD',
    symbol: 'TUSD',
  },
  */
  USDC: {
    name: 'USDCoin',
    address: '0x80ba8d0ca384fc3ca4fb8c6cbb5769276ac70a04',
    symbol: 'USDC',
    decimals: 18,
    chainId: 4,
    logoUri: '/images/USDC.png',
    amountIn: '0',
  },
}

export const goerliStableCoin: Record<string, Token> = {
  /*
  TUSD: {
    address: '0xA7da9D26A8Aa2155429c440ea05236eCE7F0ffF0',
    amountIn: '0',
    chainId: 5,
    decimals: 18,
    logoUri:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0000000000085d4780B73119b644AE5ecd22b376/logo.png',
    name: 'TrueUSD',
    symbol: 'TUSD',
  },
  */
  USDC: {
    name: 'USDCoin',
    address: '0x39e92ba4b151ee0a3756897a5303c1ed14dcbde0',
    symbol: 'USDC',
    decimals: 6,
    chainId: 5,
    logoUri: '/images/USDC.png',
    amountIn: '0',
  },
}
