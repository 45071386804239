import { ENV, APP_ENV } from 'ENV'

import { PROTOCOLS } from 'constants/env'

export const isDev = () => process.env.NODE_ENV === ENV.DEV

export const isTesting = () =>
  process.env.REACT_APP_STATIC_ENV === APP_ENV.TESTING

export const isTest = () => process.env.NODE_ENV === ENV.TEST

export function getProtocol(env: keyof typeof ENV): string {
  return PROTOCOLS[env]
}

export const isProduction = () =>
  process.env.REACT_APP_STATIC_ENV === APP_ENV.PRODUCTION

export const isStaging = () =>
  process.env.REACT_APP_STATIC_ENV === APP_ENV.STAGING
