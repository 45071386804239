import { FC, useState } from 'react'

import {
  CurrencyConfigContext,
  defaultCurrencyConfigContext,
} from './ContextProvider'

const CurrencyConfigProvider: FC = ({ children }) => {
  const [currencyConfig, setCurrencyConfig] = useState(
    defaultCurrencyConfigContext.currencyConfig
  )

  return (
    <CurrencyConfigContext.Provider
      value={{ currencyConfig, setCurrencyConfig }}
    >
      {children}
    </CurrencyConfigContext.Provider>
  )
}

export default CurrencyConfigProvider
