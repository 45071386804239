export enum CroAddress {
  Main = '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
  Ropsten = '0xb29B906e8030942F377c8488Df14d833e5Da290D',
  Rinkeby = '0x4edc40ef81cd773b690f01ea75b9ac90a87fda6e',
  Goerli = '0x1173069b0e472a64ce2b6203fec8fb25a05b75c2',
}

export enum WcroAddress {
  Cronos = '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
  CronosTest = '0x6a3173618859C7cd40fAF6921b5E9eB6A76f1fD4',
}
