import React, { FC } from 'react'
import { ComponentTypeWithI18n } from 'i18nWithNext'

import { PaymentCryptoCurrencyEnum } from 'constants/utils'
import { withTranslation } from 'components/ReactComponentWithi18n'

export enum MismatchReasons {
  underpaid = 'underpaid',
  wrongCurrency = 'wrong_currency',
  delayed = 'delayed',
  highRisk = 'high_risk',
  cancelled = 'cancelled',
  notOnWhitelist = 'not_on_whitelist',
  limitExceeded = 'exceeds_max_payment_amount_in_usd',
  // multiple = 'multiple', multiple payment, just ignore it, don't show the reason
}

interface Props {
  selectedCryptoCurrency?: string
  reason: MismatchReasons
}

const MismatchedReason: FC<ComponentTypeWithI18n<Props>> = ({
  reason,
  $t,
  selectedCryptoCurrency = PaymentCryptoCurrencyEnum.CRO,
}) => {
  const isUnderpaid = reason === MismatchReasons.underpaid
  const isWrongCurrency = reason === MismatchReasons.wrongCurrency
  const isDelayed = reason === MismatchReasons.delayed
  const isHighRisk = reason === MismatchReasons.highRisk
  const isNotOnWhitelist = reason === MismatchReasons.notOnWhitelist
  const isLimitExceeded = reason === MismatchReasons.limitExceeded

  const hasMismatch = Object.values(MismatchReasons).includes(reason)

  // Don't show the reason if there is no match
  if (!hasMismatch) return null

  return (
    <div className="p-lg">
      <div className="mx-auto bg-grey-shallow px-32 py-lg">
        {isUnderpaid && (
          <>
            <h3>{$t('underpaid')}</h3>
            <p>
              {$t('underpaidDetail', {
                cryptoCurrency: selectedCryptoCurrency,
              })}
            </p>
          </>
        )}
        {isWrongCurrency && (
          <>
            <h3>{$t('wrongCurrency')}</h3>
            <p>
              {$t('wrongCurrencyDetail', {
                cryptoCurrency: selectedCryptoCurrency,
              })}
            </p>
          </>
        )}
        {isHighRisk && (
          <>
            <h3>{$t('highRisk')}</h3>
            <p>
              {$t('highRisk_detail', {
                cryptoCurrency: selectedCryptoCurrency,
              })}
            </p>
          </>
        )}
        {isDelayed && (
          <>
            <h3>{$t('latePaid')}</h3>
            <p>
              {$t('latePaidDetail', { cryptoCurrency: selectedCryptoCurrency })}
            </p>
          </>
        )}
        {isNotOnWhitelist && (
          <>
            <h3>{$t('notOnWhitelist')}</h3>
            <p>
              {$t('notOnWhitelistDetail', {
                cryptoCurrency: selectedCryptoCurrency,
              })}
            </p>
          </>
        )}
        {isLimitExceeded && (
          <>
            <h3>{$t('limitExceeded')}</h3>
            <p>{$t('limitExceededDetail')}</p>
          </>
        )}

        <style jsx>{`
          h3 {
            font-size: 18px;
            font-weight: 600;
            color: #f76341;
            margin-bottome: 8px;
          }
          p {
            font-size: 13px;
            color: #97a5b6;
          }
        `}</style>
      </div>
    </div>
  )
}

export default withTranslation<Props>('common')(MismatchedReason)
