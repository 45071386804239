export function unpackSDKMeta(sdkMeta: string) {
  if (!sdkMeta) return ''
  const buff = Buffer.from(sdkMeta, 'base64')
  const sdkMetaUtf8 = buff.toString('utf-8')
  const { url } = JSON.parse(sdkMetaUtf8 || '{}')
  let publishKey

  if (url) {
    const params = new URLSearchParams(url.split('?')[1])

    publishKey = params.get('publishable-key')
  }

  return publishKey || ''
}
