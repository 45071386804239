import { DefaultLangEnum } from 'shared/types/Langs'

let _lang = DefaultLangEnum.en

export const setLocale = (lang: DefaultLangEnum) => {
  _lang = lang
}

export const getLocale = (): DefaultLangEnum => {
  return _lang
}
