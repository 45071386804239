import { useEffect, useState } from 'react'

export const useHasWindowProp = (propertyName: string) => {
  const [hasWindowProp, setHasWindowProp] = useState(false)

  // window?.PaymentJs is undefined when the Component first render
  // setInterval to monitoring window?.PaymentJs
  useEffect(() => {
    if (hasWindowProp) {
      return
    } else if (window[propertyName]) {
      setHasWindowProp(true)
    } else {
      const checkAPI = setInterval(() => {
        if (window[propertyName]) {
          setHasWindowProp(true)
          clearInterval(checkAPI)
        }
      }, 200)

      return () => clearInterval(checkAPI)
    }
  }, [hasWindowProp, propertyName])

  return { hasWindowProp }
}
