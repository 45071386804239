import { isEqual } from 'lodash'
import { useState, useCallback, useRef } from 'react'

import { Logger } from 'utils/helpers'

export function useAsyncCall<T>(
  callback: (...args) => Promise<T>,
  options?: {
    onFailed?: (...args) => void
  }
): [
  (...args) => Promise<T>,
  {
    result: T | null
    loading: boolean
    error: any
  }
] {
  const [returnValue, setReturnValue] = useState<T | null>(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const returnValueRef = useRef<T | null>(returnValue)
  const callFn = useCallback(
    (...args) => {
      setLoading(true)

      return callback(...args)
        .then(data => {
          if (data && !isEqual(data, returnValueRef.current)) {
            returnValueRef.current = data
            setReturnValue(data)
          }

          return data
        })
        .catch(err => {
          Logger.logError(err)
          setError(err || 'error ocurred in useAsyncCall hook')
          options?.onFailed?.(err)

          return err
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [callback, options]
  )

  return [callFn, { result: returnValue, error, loading }]
}
