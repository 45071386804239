import { getSubscriptionData } from './getApiData'
import { parseCreatePaymentError } from './paymentHandle'

export const getSubscriptionDataOnServerSide = async (
  publishableKey: string,
  id: string,
  headers?: HeadersInit
) => {
  const { errorStatusCode, errorName } = parseCreatePaymentError(id)

  if (errorStatusCode && errorName) {
    return {
      error: {
        statusCode: errorStatusCode,
        errorName,
      },
    }
  }

  return await getSubscriptionData(publishableKey, id, headers)
}
