export const EMAIL_PATTERN =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

// crypto currencies we support
export enum PaymentCryptoCurrencyEnum {
  CRO = 'CRO',
  ETH = 'ETH',
  CRO_NATIVE = 'CRO_NATIVE',
  CRO_CRONOS = 'CRO_CRONOS',
  ETH_CRONOS = 'ETH_CRONOS',
  USDC_CRONOS = 'USDC_CRONOS',
  USDT_CRONOS = 'USDT_CRONOS',
  BTC = 'BTC',
  TUSD = 'TUSD',
  TUSD_CRONOS = 'TUSD_CRONOS',
  USDC = 'USDC',
  WETH = 'WETH',
  WBTC = 'WBRC',
  USDT = 'USDT',
}

// cro currencies name
export const CRO_CURRENCIES = {
  ERC20: 'CRO',
  CRO_NATIVE: 'CRO_NATIVE',
  CRO_CRONOS: 'CRO_CRONOS',
}

export enum ExperimentalFeatures {
  payment = 'exptl_qr_code_payment',
  refund = 'exptl_qr_code_refund',
  rebound = 'exptl_qr_code_rebound',
  onchain_rebound = 'exptl_onchain_rebound',
  cronos = 'exptl_cronos',
  vvs_enabled = 'exptl_vvs_enabled',
}

export const DeFiProtocols = {
  DeFiSwap: 'defi',
  VVS: 'vvs',
}

export const DeFiSwapName = {
  DeFiSwap: 'Crypto.com DeFi Swap',
  VVS: 'VVS Finance Swap',
}

export const DEFAULT_CRYPTO_CURRENCY_PRECISION = 6

export const DEFAULT_FIAT_CURRENCY_PRECISION = 2

export const DEFAULT_ZERO_AMOUNT = '0.0'
