import camelcaseKeys from 'camelcase-keys'

import { PurchaseData } from 'components/buy_crypto/types'

import Api from './fetchApi'
import {
  checkOnfidoResultApi,
  createPurchaseApi,
  createWorkFlowRunApi,
  getAssetsApi,
  getPurchaseApi,
  getPurchaseDataPublicApi,
  getCurrentMerchantApi,
  purchasePayApi,
  refreshPurchaseApi,
  retryApi,
  saveBillingAddressApi,
  saveCardInfoApi,
  saveSessionIdApi,
  sendOtpApi,
  transactionLimitApi,
  transactionLimitConfigApi,
  updatePromotionalEmailNotificationSettingApi,
  updatePurchaseCustomerApi,
  validateOtpApi,
  getTokenPriceApi,
  getCountriesApi,
  getApplePaySessionApi,
} from './getApi'

export enum PurchaseErrorCode {
  expiredPurchaseToken = 'expired_purchase_token',
  forbidden = 'forbidden',
  apiKeyInvalid = 'api_key_invalid',
  invalidParameter = 'invalid_parameter',
  cardBindError = 'invalid_request_error',
  invalidPurchaseCard = 'invalid_purchase_card',
  purchaseNotFound = 'purchase_not_found',
  internalError = 'internal_error',
  purchaseApiFailed = 'purchase_api_failed',
  invalidPurchaseQuotation = 'invalid_purchase_quotation',
  unsupportPurchaseCurrency = 'unsupport_purchase_currency',
  invalidPurchaseStatus = 'invalid_purchase_status',
  purchaseQuotationExpired = 'purchase_quotation_expired',
  purchaseCardRejected = 'purchase_card_rejected',
  purchaseCardInvalid = 'purchase_card_invalid',
  purchaseAmountTooSmall = 'purchase_amount_too_small',
  purchaseAmountTooLarge = 'purchase_amount_too_large',
  purchaseTimeout = 'purchase_timeout',
  exceedAnnualLimit = 'exceed_annual_limit',
  exceedDailyLimit = 'exceed_daily_limit',
  payCoreApiError = 'pay_core_api_error',
  resourceMissing = 'resource_missing',
  invalidOtpToken = 'invalid_otp_token',
  expiredOtpToken = 'expired_otp_token',
  moreThanMaxAmount = 'more_than_max_amount',
  ssnRequired = 'ssn_required',
  expiredCustomerToken = 'expired_customer_token',
  invalidCustomerToken = 'invalid_customer_token',
  invalidToken = 'invalid_token',
  kycRejected = 'kyc_rejected',
  checkSsnFailed = 'check_ssn_failed',
  ssnUnknownError = 'ssn_unknown_error',
  invalidRecord = 'invalid_record',
  purchaseRejected = 'purchase_rejected',
  purchaseUnsupportedWalletAddress = 'purchase_unsupported_wallet_address',
  verifyPersonalInformationFailed = 'verify_personal_information_failed',
  addressVerificationFailed = 'address_verification_failed',
  purchaseCardDeclinedTryAgain = 'purchase_card_declined_try_again',
  purchaseInsufficientBalance = 'purchase_insufficient_balance',
  purchaseIncorrectPaymentInfo = 'purchase_incorrect_payment_info',
  purchaseCardExpired = 'purchase_card_expired',
  purchaseCardInactive = 'purchase_card_inactive',
  purchaseDeclinedBy3ds = 'purchase_declined_by_3ds',
  purchaseSystemError = 'purchase_system_error',
  purchaseSomethingWentWrong = 'purchase_something_went_wrong',
  ixoPayUnknownError = 'ixo_pay_unknown_error',
  ixoPayInvalidResponse = 'ixo_pay_invalid_response',
  ixoPayProcessingError = 'ixo_pay_processing_error',
  ixoPayTransactionNotFound = 'ixo_pay_transaction_not_found',
  tokenNotSupported = 'token_not_supported',
  onfidoKycFailed = 'onfido_kyc_failed',
  countryCodeMismatch = 'crypto_purchase_country_code_mismatch',
}

const fetch = async (
  api,
  { data = {}, headers, authToken, method = 'GET' }
) => {
  const fetcher = new Api({ headers, authToken })
  let fetchFunc = fetcher.get

  switch (method.toUpperCase()) {
    case 'POST':
      fetchFunc = fetcher.post
      break
    case 'PUT':
      fetchFunc = fetcher.put
      break
    case 'DELETE':
      fetchFunc = fetcher.delete
  }

  return await fetchFunc(api, data).then(
    data => data && camelcaseKeys(data, { deep: true })
  )
}

export async function getAssets(publishableKey: string, headers?: HeadersInit) {
  return await fetch(getAssetsApi(), {
    authToken: publishableKey,
    headers,
  })
}

export async function createPurchase(
  publishableKey: string,
  data: any,
  headers?: HeadersInit
) {
  return await fetch(createPurchaseApi(), {
    authToken: publishableKey,
    headers,
    data,
    method: 'POST',
  })
}

export async function getPurchaseData(
  publishableKey: string,
  id: string,
  headers?: HeadersInit
): Promise<PurchaseData> {
  return await fetch(getPurchaseApi(id), {
    headers,
    authToken: publishableKey,
  })
}

export async function sendOtp(publishableKey, params) {
  return await fetch(sendOtpApi(), {
    headers: {},
    authToken: publishableKey,
    data: params,
    method: 'POST',
  })
}

export async function validateOtp(
  publishableKey: string,
  params: { purchase_id: string; email: string; token: string }
) {
  return await fetch(validateOtpApi(), {
    headers: {},
    authToken: publishableKey,
    data: params,
    method: 'POST',
  })
}

export async function saveCard(publishableKey, headers, params) {
  return await fetch(saveCardInfoApi(), {
    headers,
    authToken: publishableKey,
    data: params,
    method: 'POST',
  })
}

export async function saveBillingAddress(publishableKey, id, headers, params) {
  return await fetch(saveBillingAddressApi(id), {
    headers,
    authToken: publishableKey,
    data: params,
    method: 'PUT',
  })
}

export async function purchasePay(publishableKey, id, headers, params) {
  return await fetch(purchasePayApi(id), {
    headers,
    authToken: publishableKey,
    data: params,
    method: 'POST',
  })
}

export async function refreshPurchase(publishableKey, id, headers) {
  return await fetch(refreshPurchaseApi(id), {
    headers,
    authToken: publishableKey,
    method: 'POST',
  })
}

export async function getPurchaseDataPublic(
  publishableKey,
  id,
  headers = {}
): Promise<PurchaseData> {
  return await fetch(getPurchaseDataPublicApi(id), {
    headers,
    authToken: publishableKey,
    method: 'GET',
  })
}

export async function getTransactionLimit(publishableKey, id, headers = {}) {
  return await fetch(transactionLimitApi(id), {
    headers,
    authToken: publishableKey,
    method: 'GET',
  })
}

export async function updatePurchaseCustomer(
  publishableKey,
  headers = {},
  params
) {
  return await fetch(updatePurchaseCustomerApi(), {
    headers,
    authToken: publishableKey,
    method: 'PUT',
    data: params,
  })
}

export async function deleteCard(publishableKey, id, headers) {
  return await fetch(saveBillingAddressApi(id), {
    headers,
    authToken: publishableKey,
    method: 'DELETE',
  })
}

export async function getTransactionLimitConfig() {
  return await fetch(transactionLimitConfigApi(), {
    method: 'GET',
    headers: {},
    authToken: undefined,
  })
}

export async function getCurrentMerchant(publishableKey) {
  return await fetch(getCurrentMerchantApi(publishableKey), {
    headers: {},
    authToken: publishableKey,
    method: 'GET',
  })
}

export async function saveSessionId(publishableKey, id, sessionId, headers) {
  return await fetch(saveSessionIdApi(id), {
    headers,
    authToken: publishableKey,
    method: 'PUT',
    data: {
      session_id: sessionId,
    },
  })
}

export async function retry(publishableKey, id, headers) {
  return await fetch(retryApi(id), {
    headers,
    authToken: publishableKey,
    method: 'PUT',
  })
}

export async function updatePromotionalEmailNotificationSetting(
  publishableKey,
  id,
  checked,
  headers
) {
  return await fetch(updatePromotionalEmailNotificationSettingApi(id), {
    headers,
    authToken: publishableKey,
    method: 'PUT',
    data: {
      promotional_email: checked,
    },
  })
}

export async function createWorkFlowRun(publishableKey, id, headers) {
  return await fetch(createWorkFlowRunApi(), {
    headers,
    authToken: publishableKey,
    method: 'POST',
    data: {
      purchase_id: id,
    },
  })
}

export async function checkOnfidoResult(
  publishableKey,
  id,
  workflowRunId,
  headers
) {
  return await fetch(checkOnfidoResultApi(), {
    headers,
    authToken: publishableKey,
    method: 'POST',
    data: {
      purchase_id: id,
      workflow_run_id: workflowRunId,
    },
  })
}

export async function getTokenPrice(publishableKey, currency, headers = {}) {
  return await fetch(getTokenPriceApi(currency), {
    headers,
    method: 'GET',
    authToken: publishableKey,
  })
}

export async function getCountries(publishableKey, headers = {}) {
  return await fetch(getCountriesApi(), {
    headers,
    method: 'GET',
    authToken: publishableKey,
  })
}

export async function getApplePaySession(publishableKey, headers = {}) {
  return await fetch(getApplePaySessionApi(), {
    headers,
    method: 'GET',
    authToken: publishableKey,
  })
}
