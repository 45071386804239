export enum ENV {
  DEV = 'development',
  TEST = 'test',
  PRODUCTION = 'production',
}

export const PROTOCOLS = {
  [ENV.DEV]: 'http',
  [ENV.TEST]: 'https',
  [ENV.PRODUCTION]: 'https',
}
