import { NetworkID } from '..'
import { Token } from '../web3'

import { PolygonMainNetTransferCoins } from './'

export const getPolygonTransferCoinMap = (
  chainId: NetworkID
): Record<string, Token> => {
  switch (chainId) {
    case NetworkID.Polygon:
      return PolygonMainNetTransferCoins
    default:
      return PolygonMainNetTransferCoins
  }
}
