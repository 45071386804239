export const hasErrors = (errors: Record<string, unknown>) =>
  Object.keys(errors).length > 0

export function addServerErrors<T>(
  errors: { [P in keyof T]?: string[] },
  setError: (
    fieldName: keyof T,
    error: { type: string; message: string }
  ) => void
) {
  return Object.keys(errors).forEach(key => {
    setError(key as keyof T, {
      type: 'server',
      message: errors[key as keyof T]?.join('. ') ?? '',
    })
  })
}
