import React, { FC, useCallback, useState, useEffect, useMemo } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
interface Options {
  type?: MessageType
  duration?: number
  title: string | JSX.Element
  onClose?: () => void
}
export type MessageApi = (options: MessageOptions) => void
type MessageOptions = string | Options
interface Props<T> {
  registerFallback: (callApi: (option: T) => void) => void
}

type MessageList = Array<
  {
    key: string
  } & Options
>

type MessageType = 'info' | 'success' | 'error' | 'warning'
const defaultType = 'info'
const defaultDuration = 2000
const Message: FC<Props<MessageOptions>> = ({ registerFallback }) => {
  const [messageList, setMessageList] = useState<MessageList>([])
  const memoList: MessageList = useMemo(() => [], [])

  const deleteMessage = useCallback(
    (key: string) => {
      const targetMessageIndex = memoList.findIndex(
        message => message.key === key
      )

      memoList.splice(targetMessageIndex, 1)
      setMessageList([...memoList])
    },
    [memoList]
  )

  const addMessage = useCallback(
    (option: MessageOptions) => {
      const key = Date.now().toString()
      let item
      let duration = defaultDuration

      if (typeof option === 'string') {
        item = {
          type: defaultType,
          title: option,
        }
      } else {
        duration = option.duration || defaultDuration
      }

      memoList.splice(0, 0, {
        key,
        ...(item || Object.assign({ type: defaultType }, option)),
      })

      setMessageList([...memoList])
      setTimeout(() => {
        deleteMessage(memoList[0].key)
        if (typeof option !== 'string' && option.onClose) {
          option.onClose()
        }
      }, duration)
    },
    [deleteMessage, memoList]
  )

  const messageApi = useCallback(
    (option: MessageOptions) => {
      addMessage(option)
    },
    [addMessage]
  )

  useEffect(() => {
    registerFallback(messageApi)
  }, [registerFallback, messageApi])

  return (
    <div className="sdk-message">
      <TransitionGroup>
        {messageList.map(({ key, title, type }) => {
          return (
            <CSSTransition key={key} in={true} timeout={300} classNames="fade">
              <div
                key={key}
                className={`sdk-message-item ${type} ${
                  title ? 'visible' : ''
                } mb-sm`}
              >
                <span key={key}>{title}</span>
              </div>
            </CSSTransition>
          )
        })}
      </TransitionGroup>

      <style jsx>{`
        .sdk-message {
          width: 100%;
          height: auto;
          position: fixed;
          top: 50%;
          left: 0;
          box-sizing: border-box;
          margin-top: -32px;
          z-index: 110;
          text-align: center;
          pointer-events: none;
        }
        .fade-enter {
          transform: translateY(100%) scale(0);
          opacity: 0;
        }
        .fade-enter-active {
          opacity: 1;
          transform: translateY(0%) scale(1);
          transition: all 0.3s ease-out;
        }
        .fade-exit {
          transform: translateY(0%) scale(1);
          opacity: 1;
        }
        .fade-exit-active {
          opacity: 0;
          transform: translateY(-100%) scale(0);
          transition: all 0.1s ease-in;
        }
        .sdk-message-item span {
          padding: 20px 40px;
          font-size: 16px;
          border-radius: 39.5px;
          display: inline-block;
        }
        .sdk-message-item.info span {
          color: #fff;
          background-color: rgba(15, 28, 57, 0.8);
        }
      `}</style>
    </div>
  )
}

export default Message
