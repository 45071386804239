import { useRouter } from 'next/router'
import { useContext } from 'react'

import { SessionContext } from 'components/ContextProvider'
import { getPaymentRouterQueries } from 'utils/route'

export function usePublishableKey(): string {
  const { publishableKey } = useContext(SessionContext)
  const router = useRouter()
  const routerQueries = getPaymentRouterQueries(router)

  return publishableKey || routerQueries.publishableKey || ''
}
