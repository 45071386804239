import { useMemo, useContext } from 'react'

import { getHandlePaymentData } from 'utils/api/handlePaymentData'
import {
  WebSocketContext,
  IWebSocketContext,
} from 'components/WebSocketProvider'
import { PaymentResponseType } from 'shared/types/payment'
import { ResponseData } from 'utils/api'

// Tips: should use it within websocket provider
export const usePaymentData = () => {
  const { lastData: payment } =
    useContext<IWebSocketContext<ResponseData<PaymentResponseType>>>(
      WebSocketContext
    )

  // Todo: should add typings for getHandlePaymentData function
  return useMemo(() => getHandlePaymentData(payment), [payment])
}
