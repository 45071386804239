import React, { ComponentType, FC, memo } from 'react'
import { isEqual } from 'lodash'

interface Props {
  component: ComponentType
}
const PureContainerFC: FC<Props> = ({ children, component: Com, ...rest }) => {
  return <Com {...rest}>{children}</Com>
}

export default memo<Props>(PureContainerFC, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps)
})
