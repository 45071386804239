import { NetworkID } from '..'

import { CroAddress } from './croAddress'

export const getEtherCroAddress = (chainId: number): string => {
  switch (chainId) {
    case NetworkID.Main:
      return CroAddress.Main
    case NetworkID.Ropsten:
      return CroAddress.Ropsten
    case NetworkID.Goerli:
      return CroAddress.Goerli
    default:
      return CroAddress.Rinkeby
  }
}
