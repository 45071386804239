/**
 * @file server side api requests
 * @description please only call this api on server side
 * */

import camelcaseKeys from 'camelcase-keys'

import { NEXT_API_PATHS } from 'constants/apiPaths'
import { CurrencyConfig } from 'components/ContextProvider'
import { OutboundData } from 'server/types/outboundTypes'
import { SubscriptionData } from 'shared/types/subscription'

import Api from './fetchApi'
import {
  getCheckOnchainOutboundStatusApi,
  getCurrencyConfigs,
  getPaymentApi,
  getReportLocationApi,
  getSubscriptionApi,
} from './getApi'

export const getOutboundData = (token: string, headers?: HeadersInit) => {
  const fetcher = new Api({ headers })
  const checkOnchainOutboundStatusApi = getCheckOnchainOutboundStatusApi(token)

  return fetcher.get<OutboundData>(checkOnchainOutboundStatusApi)
}

export const getPaymentData = async (
  publishableKey: string,
  id: string,
  headers?: HeadersInit
) => {
  const fetcher = new Api({ authToken: publishableKey, headers })
  const paymentApi = getPaymentApi(id)

  let payment = {}
  let extraInfo = {}

  if (paymentApi) {
    const {
      data: promiseData,
      headers,
      error,
    } = await fetcher.getExtra(paymentApi)

    if (error) {
      return {
        error,
      }
    }
    payment = await promiseData.then(data => data)

    extraInfo = {
      headers: {
        sign: headers.get('x-sign'),
      },
    }
  }

  return {
    payment,
    extraInfo,
  }
}

export const getSubscriptionData = async (
  publishableKey: string,
  id: string,
  headers?: HeadersInit
) => {
  const fetcher = new Api({ authToken: publishableKey, headers })
  const subscriptionApi = getSubscriptionApi(id)
  const payment: SubscriptionData = subscriptionApi
    ? await fetcher.get(subscriptionApi).then(data => data)
    : {}

  return payment
}

interface VersionResponse {
  buildId: string
}
export const getLatestVersion = async () => {
  return await Api.fetch<VersionResponse>(NEXT_API_PATHS.getVersion).then(
    (res: VersionResponse) => res
  )
}

export const getCurrencyConfig = async (
  publishableKey: string,
  headers?: HeadersInit
) => {
  const fetcher = new Api({ authToken: publishableKey, headers })
  const { chains, tokens, tokensByNetwork, fiats }: CurrencyConfig =
    await fetcher.get(getCurrencyConfigs()).then(data => ({
      chains: camelcaseKeys(data.chains, { deep: true }),
      tokens: camelcaseKeys(data.base_tokens, { deep: true }),
      fiats: camelcaseKeys(data.fiats),
      tokensByNetwork: (_data => {
        Object.keys(_data).forEach(
          key => (_data[key] = camelcaseKeys(_data[key], { deep: true }))
        )

        return _data
      })(data.base_tokens_by_network),
    }))

  return {
    chains,
    tokens,
    tokensByNetwork,
    fiats,
  }
}
export const reportLocation = async (
  publishableKey: string,
  paymentId: string
) => {
  const fetcher = new Api({ authToken: publishableKey })

  fetcher
    .post(getReportLocationApi(), {
      payment_id: paymentId,
    })
    .then()
}
