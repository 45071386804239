import { Logger } from './loggerHelper'

export function replaceData(
  str: string,
  data: Record<string, string | number>
) {
  let _str = str

  Object.keys(data).map(key => {
    const reg = new RegExp(`<%=\\s*${key}\\s*%>`, 'g')

    _str = _str.replace(reg, String(data[key]))
  })

  return _str
}

export const addZero = (x: number): string => {
  return x >= 0 && x < 10 ? `0${x}` : `${x}`
}

export function clipboardCopy(text: string): Promise<void> {
  // Use the Async Clipboard API when available. Requires a secure browing
  // context (i.e. HTTPS)
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text).catch(function (err): never {
      throw err !== undefined
        ? err
        : new DOMException('The request is not allowed', 'NotAllowedError')
    })
  }

  // ...Otherwise, use document.execCommand() fallback

  // Put the text to copy into a <span>
  const span = document.createElement('span')

  span.textContent = text

  // Preserve consecutive spaces and newlines
  span.style.whiteSpace = 'pre'

  // Add the <span> to the page
  document.body.appendChild(span)

  // Make a selection object representing the range of text selected by the user
  const selection = window.getSelection()
  const range = window.document.createRange()

  selection?.removeAllRanges()
  range.selectNode(span)
  selection?.addRange(range)

  // Copy text to the clipboard
  let success = false

  try {
    success = window.document.execCommand('copy')
  } catch (err) {
    Logger.logError('error', err)
  }

  // Cleanup
  selection && selection.removeAllRanges()
  window.document.body.removeChild(span)

  return success
    ? Promise.resolve()
    : Promise.reject(
        new DOMException('The request is not allowed', 'NotAllowedError')
      )
}

export const getCookieField = (key: string) => {
  const cookie = document.cookie
  const cookieArr = cookie ? cookie.split('; ') : []
  const cookieMap: any = {}

  cookieArr.forEach(item => {
    const itemArr = item.split('=')
    const key = itemArr.length === 2 ? itemArr[0] : null
    const value = itemArr.length === 2 ? itemArr[1] : null

    if (key) {
      cookieMap[key] = value
    }
  })

  return cookieMap[key]
}

export const setCookieField = (key: string, value: string) => {
  const cookie = document.cookie
  const cookieArr = cookie ? cookie.split('; ') : []
  const cookieMap: any = {}

  cookieArr.forEach(item => {
    const itemArr = item.split('=')
    const key = itemArr.length === 2 ? itemArr[0] : null
    const value = itemArr.length === 2 ? itemArr[1] : null

    if (key) {
      cookieMap[key] = value
    }
  })
  cookieMap[key] = value
  let newCookie = ''
  const cookies = Object.keys(cookieMap)

  cookies.forEach((key, i) => {
    newCookie += `${key}=${cookieMap[key]}${i < cookies.length ? '; ' : ''}`
  })
  document.cookie = newCookie
}

export const getBooleanFromStr = (value?: string | string[]) => value === 'true'

export const sleep = (ms = 1500) =>
  new Promise(resolve => setTimeout(resolve, ms))

export const isNumberString = (num?: string) => {
  if (!num) {
    return false
  }

  return !Number.isNaN(Number(num))
}
