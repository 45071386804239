// Note: Find more info about WebSocket Integration at
// https://monacohq.atlassian.net/wiki/spaces/PayFE/pages/2429288946
import React, {
  createContext,
  memo,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import io, { Socket } from 'socket.io-client'
import { v4 as uuidV4 } from 'uuid'

import { isMobileDevice } from 'shared/utils'
import { useQuery } from 'hooks/index'

export enum ConnectionState {
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  UNINITIALIZED = 'uninitialized',
}

const WebSocketCustomEvent = {
  PAYMENT: 'pay_checkout',
}

const Platform = {
  Mobile: 'h5',
  Desktop: 'web',
}

const defaultWebsocketContext = {
  lastData: {},
  connectionState: ConnectionState.UNINITIALIZED,
  pollingError: null,
  fallbackEnabled: false,
  closePolling: () => {},
  setWsData: () => {},
}

export interface IWebSocketContext<T> {
  lastData: T
  connectionState: ConnectionState
  pollingError: any
  fallbackEnabled: boolean
  closePolling: () => void
  setWsData: (T) => void
}

export const WebSocketContext = createContext<IWebSocketContext<any>>(
  defaultWebsocketContext
)

const socketEventHandlers = (successHandler, errorHandler) => {
  return message => {
    const { code, data } = message || {}

    if (code === '200') {
      successHandler(data)
    } else {
      errorHandler(message)
    }
  }
}

export interface Props<T> {
  children?: React.ReactNode
  authSign: string
  socketUrl: string
  paymentId: string
  fallbackAPI?: string
  fallbackPollingInterval?: number
  defaultData: T
}

export type WebSocketContextProviderType = <T>(props: Props<T>) => JSX.Element

const WebSocketContextProvider: WebSocketContextProviderType = ({
  children,
  socketUrl,
  paymentId,
  fallbackAPI,
  fallbackPollingInterval = 3000,
  defaultData,
  authSign,
}) => {
  // Socket state
  const [socketError, setSocketError] = useState(null)
  const [socketInstance, setSocketInstance] = useState<Socket | null>(null)
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.UNINITIALIZED
  )

  // States of polling fallback solution for websocket
  const [fallbackEnabled, setFallbackEnabled] = useState(false)
  const {
    data: pollingData,
    error: pollingError,
    closeRefreshInterval: closePolling,
    // refetch: refreshPolling,
  } = useQuery(fallbackAPI || '', {
    isPaused: () => !fallbackAPI || !fallbackEnabled,
    refreshInterval: fallbackPollingInterval,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenHidden: true,
    refreshWhenOffline: false,
  })
  // Local state
  const [mainData, setMainData] = useState<any>(defaultData)
  const [lastData, setLastData] = useState<any>(defaultData)

  const supportsWebSocket = useMemo(
    () =>
      typeof window !== 'undefined' &&
      !isMobileDevice(window.navigator.userAgent) &&
      ('WebSocket' in window || 'MozWebSocket' in window),
    []
  )

  const disconnect = useCallback(
    (socket = socketInstance) => {
      if (socket) {
        socket.disconnect()
        setSocketInstance(undefined)
        setConnectionState(ConnectionState.DISCONNECTED)
      }
    },
    [socketInstance]
  )

  useEffect(() => {
    if (socketInstance) {
      socketInstance.on('connect', async () => {
        setConnectionState(ConnectionState.CONNECTED)
      })

      socketInstance.on(
        WebSocketCustomEvent.PAYMENT,
        socketEventHandlers(
          data => {
            setMainData(data)
          },
          err => {
            setSocketError(err)
          }
        )
      )

      socketInstance.on('reconnecting', async () => {
        setConnectionState(ConnectionState.CONNECTING)
      })
      socketInstance.on('reconnect', async () => {
        setConnectionState(ConnectionState.CONNECTED)
      })

      socketInstance.on('disconnect', reason => {
        setConnectionState(ConnectionState.DISCONNECTED)

        if (reason === 'io server disconnect') {
        }
      })
      socketInstance.on('connect_error', err => {
        console.error('ws connect_error', err)
        setSocketError(err)
      })
      socketInstance.on('reconnect_error', err => {
        console.error('ws reconnect_error', err)
        setSocketError(err)
      })
      socketInstance.on('reconnect_failed', err => {
        console.error('ws reconnect_failed', err)
        setSocketError(err)
      })
    }
  }, [socketInstance])

  useEffect(() => {
    if (supportsWebSocket && paymentId && authSign) {
      setConnectionState(ConnectionState.CONNECTING)

      // 32-bit signed integer
      const randomId = uuidV4()
        ?.split('-')
        .reduce((a, b) => a + b)
      const isMobile = isMobileDevice(window.navigator.userAgent)

      const socket = io(socketUrl, {
        transports: ['websocket'],
        query: {
          systemType: 'payment', // only for SDK
          deviceId: randomId,
          paymentId: paymentId,
          source: isMobile ? Platform.Mobile : Platform.Desktop,
          sign: authSign,
        },
        reconnection: true, // whether to reconnect automatically
        reconnectionAttempts: 5, // number of reconnection attempts before giving up
        reconnectionDelay: 3000, // how long to initially wait before attempting a new reconnection
        reconnectionDelayMax: 5000, // how long to wait between reconnection attempts
        autoConnect: true, // whether to connect automatically on mount
      })

      setSocketInstance(socket)

      return () => {
        disconnect(socket)
        fallbackEnabled && closePolling()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    switch (connectionState) {
      case ConnectionState.CONNECTED: {
        setSocketError(null)
      }
      // case ConnectionState.DISCONNECTED: {
      //   setFallbackEnabled(true)
      // }
    }
    console.log(`ws connection status: ${connectionState}`)
  }, [connectionState])

  useEffect(() => {
    const enable =
      !supportsWebSocket ||
      !!socketError ||
      connectionState === ConnectionState.DISCONNECTED

    setFallbackEnabled(enable)
  }, [connectionState, supportsWebSocket, socketError])

  useEffect(() => {
    const newData = fallbackEnabled && pollingData ? pollingData : mainData

    if (fallbackEnabled && pollingData) {
      // reset main data if polling data is received
      setMainData(pollingData)
    }

    setLastData(newData)
  }, [fallbackEnabled, mainData, pollingData])

  useEffect(() => {
    console.log('fallbackEnabled', fallbackEnabled, new Date() + '')
  }, [fallbackEnabled])

  return (
    <WebSocketContext.Provider
      value={{
        connectionState,
        lastData,
        pollingError,
        fallbackEnabled,
        closePolling,
        setWsData: setMainData,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  )
}

export default memo(WebSocketContextProvider)
