import { TransactionReceipt, PromiEvent } from 'web3-core'

import {
  ExperimentalFeatures,
  DeFiProtocols,
  DeFiSwapName,
} from 'constants/utils'

export enum NetworkID {
  None = 0,
  Main = 1,
  Ropsten = 3,
  Rinkeby = 4,
  Goerli = 5,
  BSC = 56,
  Polygon = 137,
  Polygon_test = 80002,
  Cronos_test = 338,
  Cronos = 25,
  ArbitrumOne = 42161,
  ZkSync = 324,
  Sepolia = 11155111,
}

export interface Transaction {
  send: (attrs: any) => PromiEvent<TransactionReceipt>
  estimateGas: (attrs?: any) => Promise<number>
}

export const CHAIN_LIST = [
  {
    chainId: NetworkID.Main,
    name: 'Ethereum Mainnet',
    symbol: 'ETH',
    nativeCurrency: {
      symbol: 'ETH',
      name: 'Ether',
      decimals: 18,
    },
    prod: true,
    rpcUrls: ['https://mainnet.infura.io/v3/'],
    blockExplorerUrls: ['https://etherscan.io'],
  },
  {
    chainId: NetworkID.Sepolia,
    name: 'Sepolia',
    symbol: 'ETH',
    nativeCurrency: {
      symbol: 'ETH',
      name: 'Ether',
      decimals: 18,
    },
    prod: false,
    rpcUrls: ['https://eth-sepolia.g.alchemy.com/v2/demo'],
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
  },
  {
    chainId: NetworkID.Ropsten,
    name: 'Ethereum Ropsten Test',
    symbol: 'ETH',
    nativeCurrency: {
      symbol: 'ETH',
      name: 'Ether',
      decimals: 18,
    },
    prod: false,
    rpcUrls: ['https://ropsten.infura.io/v3/'],
    blockExplorerUrls: ['https://ropsten.etherscan.io'],
  },
  {
    chainId: NetworkID.Rinkeby,
    name: 'Ethereum Rinkeby Test',
    symbol: 'ETH',
    nativeCurrency: {
      symbol: 'ETH',
      name: 'Ether',
      decimals: 18,
    },
    prod: false,
    rpcUrls: ['https://rinkeby.infura.io/v3/'],
    blockExplorerUrls: ['https://rinkeby.etherscan.io'],
  },
  {
    chainId: NetworkID.Goerli,
    name: 'Ethereum Goerli Test',
    symbol: 'ETH',
    nativeCurrency: {
      symbol: 'ETH',
      name: 'Ether',
      decimals: 18,
    },
    prod: false,
    rpcUrls: ['https://goerli.infura.io/v3/'],
    blockExplorerUrls: ['https://goerli.etherscan.io'],
  },
  {
    chainId: NetworkID.BSC,
    name: 'Binance Smart Chain',
    symbol: 'BSC',
    nativeCurrency: {
      symbol: 'BNB',
      name: 'Binance Coin',
      decimals: 8,
    },
    prod: false,
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  {
    chainId: NetworkID.Polygon,
    name: 'Matic Network',
    symbol: 'MATIC',
    nativeCurrency: {
      symbol: 'MATIC',
      name: 'MATIC',
      decimals: 18,
    },
    prod: false,
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  {
    chainId: NetworkID.Polygon_test,
    name: 'Mumbai',
    symbol: 'MATIC',
    nativeCurrency: {
      symbol: 'MATIC',
      name: 'MATIC',
      decimals: 18,
    },
    prod: false,
    rpcUrls: ['https://rpc-amoy.polygon.technology'],
    blockExplorerUrls: ['https://www.oklink.com/amoy'],
  },
  {
    chainId: NetworkID.Cronos,
    name: 'Cronos Mainnet',
    symbol: 'CRO',
    nativeCurrency: {
      symbol: 'CRO',
      name: 'Cronos',
      decimals: 18,
    },
    featureFlag: ExperimentalFeatures.cronos,
    prod: true,
    rpcUrls: ['https://evm.cronos.org'],
    blockExplorerUrls: ['https://cronoscan.com'],
  },
  {
    chainId: NetworkID.Cronos_test,
    name: 'Cronos Test Network',
    symbol: 'CRO',
    nativeCurrency: {
      symbol: 'CRO',
      name: 'Cronos',
      decimals: 18,
    },
    featureFlag: ExperimentalFeatures.cronos,
    prod: false,
    rpcUrls: ['https://evm-t3.cronos.org'],
    blockExplorerUrls: ['https://testnet.cronoscan.com'],
  },
  {
    chainId: NetworkID.ArbitrumOne,
    name: 'Arbitrum LlamaNodes',
    symbol: 'ETH',
    nativeCurrency: {
      symbol: 'ETH',
      name: 'ETH-Arbitrum',
      decimals: 18,
    },
    prod: true,
    rpcUrls: ['https://arbitrum.llamarpc.com'],
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  {
    chainId: NetworkID.ZkSync,
    name: 'zkSync Era',
    symbol: 'ETH',
    nativeCurrency: {
      symbol: 'ETH',
      name: 'ETH-ZKSYNC_ERA',
      decimals: 18,
    },
    prod: true,
    rpcUrls: ['https://mainnet.era.zksync.io'],
    blockExplorerUrls: ['https://explorer.zksync.io'],
  },
]

export const getDeFiProtocolByChain = (chainId: NetworkID) => {
  switch (chainId) {
    case NetworkID.Main:
      //case NetworkID.Goerli:
      // case NetworkID.Rinkeby:
      // case NetworkID.Ropsten:
      return DeFiProtocols.DeFiSwap

    case NetworkID.Cronos:
      // case NetworkID.Cronos_test:
      return DeFiProtocols.VVS

    default:
      return ''
  }
}

export const getDeFiSwapNameByChainId = (chainId: NetworkID) => {
  switch (chainId) {
    case NetworkID.Main:
    case NetworkID.Rinkeby:
    case NetworkID.Ropsten:
    case NetworkID.Goerli:
      return DeFiSwapName.DeFiSwap

    case NetworkID.Cronos_test:
    case NetworkID.Cronos:
      return DeFiSwapName.VVS

    default:
      return DeFiSwapName.DeFiSwap
  }
}
