import { UrlObject } from 'url'

import { NextRouter } from 'next/router'

import { DefaultLangEnum } from 'shared/types/Langs'

import { getBooleanFromStr } from '../helpers'
import { unpackSDKMeta } from '../nodeTools'
import { getSystemMaintenanceTime } from '../getSystemMaintenanceTime'

export const goTo = (router: NextRouter, urlObject: UrlObject) => {
  const lng = router.locale || router.defaultLocale

  router.push(
    urlObject,
    {
      pathname: `/${lng}${urlObject.pathname}`,
      query: urlObject.query,
    },
    {
      locale: lng,
    }
  )
}

interface Queries {
  sdkMeta?: string
  publishableKey?: string
  isSandbox?: boolean
  id?: string
  isCheckout?: boolean
  useWeb3?: boolean
  isWalletConnect?: boolean
  isMetaMask?: boolean
  isOtherWalletForOnchain?: boolean
  offchain?: boolean
  customerEmail?: string
  from?: string
  networkCost?: string
  recipient?: string
  defaultLang?: DefaultLangEnum
  isAllowPayLater?: boolean
}
export const getPaymentRouterQueries = (router: NextRouter) => {
  const {
    sdkMeta = '',
    publishableKey,
    token,
    id,
    isSandbox,
    isCheckout,
    isMetaMask,
    isWalletConnect,
    recipient,
    customerEmail,
    useWeb3,
    from,
    isOtherWalletForOnchain,
    offchain,
    networkCost,
    defaultLang,
    isAllowPayLater,
  } = router.query || {}

  return {
    publishableKey:
      (publishableKey as string) || unpackSDKMeta(sdkMeta as string),
    id: id as string,
    token: token as string,
    isSandbox: getBooleanFromStr(isSandbox),
    isCheckout: getBooleanFromStr(isCheckout),
    isMetaMask: getBooleanFromStr(isMetaMask),
    isWalletConnect: getBooleanFromStr(isWalletConnect),
    isAllowPayLater: getBooleanFromStr(isAllowPayLater),
    recipient: (recipient as string) || '',
    customerEmail: (customerEmail as string) || '',
    useWeb3: getBooleanFromStr(useWeb3),
    from: (from as string) || '',
    isOtherWalletForOnchain: getBooleanFromStr(isOtherWalletForOnchain),
    offchain: getBooleanFromStr(offchain),
    networkCost: (networkCost as string) || '',
    defaultLang: (defaultLang as string) ?? '',
  }
}

export interface ErrorOfPage {
  statusCode: number
  message: string
  errorName?: string
  arguments?: {
    merchant_support_email?: string
  }
}

export function goToErrorPage(router: NextRouter, error: ErrorOfPage | null) {
  if (error?.statusCode === 503) {
    getSystemMaintenanceTime().then(maintenanceTime => {
      goTo(router, {
        pathname: '/error',
        query: {
          statusCode: error.statusCode,
          errorName: error.errorName || error.message,
          maintenanceTime,
        },
      })
    })

    return
  }
  goTo(router, {
    pathname: '/error',
    query: {
      statusCode: error?.statusCode,
      errorName: error?.errorName || error?.message,
      merchantSupportEmail: error?.arguments?.merchant_support_email,
    },
  })
}

export function goToNotFoundPage(router: NextRouter) {
  router.push('/404')
}

export const getOffchainPaymentQueries = (
  defaultQueries?: Queries
): Queries => {
  return {
    ...defaultQueries,
    useWeb3: false,
    isMetaMask: false,
    isWalletConnect: false,
    isOtherWalletForOnchain: false,
    offchain: true,
  }
}

export const getOnchainPaymentQueries = (defaultQueries?: Queries): Queries => {
  return {
    ...defaultQueries,
    useWeb3: false,
    isMetaMask: false,
    isWalletConnect: false,
    isOtherWalletForOnchain: true,
    offchain: false,
  }
}

export const getMetamaskQueries = (defaultQueries?: Queries): Queries => {
  return {
    ...defaultQueries,
    useWeb3: true,
    isMetaMask: true,
    isWalletConnect: false,
    isOtherWalletForOnchain: false,
    offchain: false,
  }
}

export const getWalletConnectQueries = (defaultQueries?: Queries): Queries => {
  return {
    ...defaultQueries,
    useWeb3: true,
    isMetaMask: false,
    isWalletConnect: true,
    isOtherWalletForOnchain: false,
    offchain: false,
  }
}

export enum FromPage {
  setEmail = 'set_email',
  setWallet = 'set_wallet',
  confirming = 'confirming',
  checkout = 'checkout',
  invoice = 'invoice',
}
