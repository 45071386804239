const ENV = {
  DEV: 'development',
  TEST: 'test',
  PRODUCTION: 'production',
}

const APP_ENV = {
  TESTING: 'testing',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

const PROTOCOLS = {
  [ENV.DEV]: 'http',
  [ENV.TEST]: 'https',
  [ENV.PRODUCTION]: 'https',
  [ENV.STAGING]: 'https',
}

const API_PROTOCOLS = {
  [ENV.DEV]: process.env.API_PROTOCOL || 'https',
  [ENV.TEST]: 'https',
  [ENV.PRODUCTION]: 'https',
  [ENV.STAGING]: 'https',
}

const SDK_HOSTS = {
  [ENV.DEV]: 'localhost:9001',
  [ENV.TEST]: 'sdk.test.host',
  [ENV.PRODUCTION]: 'js.crypto.com',
  [ENV.STAGING]: 'js.3ona.co',
}

const SDK_FILE_NAME = 'cryptopay'

const API_HOSTS = {
  [ENV.DEV]: process.env.API_HOST || 'localhost:3001',
  [ENV.TEST]: 'api.test.host',
  [ENV.PRODUCTION]: 'pay.crypto.com',
  [ENV.STAGING]: 'pay.3ona.co',
}

function jsonifyPrimitives(item) {
  if (Array.isArray(item)) {
    return JSON.stringify(item)
  } else if (typeof item === 'object' && item !== null) {
    if (item.hasOwnProperty('__literal__')) {
      return item.__literal__
    }
    const result = {}

    for (const key of Object.keys(item)) {
      result[key] = jsonifyPrimitives(item[key])
    }

    return result
  } else if (
    typeof item === 'string' ||
    typeof item === 'number' ||
    typeof item === 'boolean' ||
    item === null ||
    item === undefined
  ) {
    return JSON.stringify(item)
  } else if (typeof item === 'function') {
    return item()
  } else {
    throw new TypeError(`Unrecognized type: ${typeof item}`)
  }
}

module.exports = {
  ENV,
  APP_ENV,
  PROTOCOLS,
  API_PROTOCOLS,
  SDK_HOSTS,
  SDK_FILE_NAME,
  API_HOSTS,
  jsonifyPrimitives,
}
